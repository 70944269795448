<template>
  <div>
    <ViewIdentity name="Tools"></ViewIdentity>
    <Toggle name="Enable Tracking Notifications"/>
  </div>
</template>
<script>
import ViewIdentity from "@/components/ViewIdentity";
import Toggle from "@/components/Toggle";
export default {
  components: {ViewIdentity, Toggle},

}
</script>