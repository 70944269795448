<template>
  <div>
    <div class="my-1 p-1 border container-fluid text-muted" v-if="showSearch">
      <div class="row d-inline-flex form-group">
        <div class="col m-3">
          <label class="ml-2" for="search">Track a Shipment</label>
          <input class="ml-2" placeholder="Order Id" type="text" v-model="search"/>
          <button
            type="button"
            class="btn btn-primary ml-2"
            @click="getTracking"
          >
            Search
          </button>
        </div>
      </div>
    </div>
    <!--Table-->
    <div class="row mx-auto no-gutters">
      <label v-if="items.length == 0">No tracking data for order: {{this.search}}</label>
      <b-table v-if="items.length > 0" sticky-header="600px" fixed responsive striped hover bordered
              :items="items"
              :fields="fields"
              :busy="isBusy">
        <!--Table loading spinner/label-->
        <template v-if="isBusy" #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Retrieving...</strong>
          </div>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import OrderService from "@/common/orders.service";

export default {
  mounted() {
    this.search = this.$route.query.orderId;
    if(this.search){
      this.showSearch = false;
      this.getTracking();
    }
  },
  methods: {
    getTracking() {
      this.isBusy = true;
      OrderService.getTracking(this.search)
      .then(data => {
        this.items = data;
        if(data.length === 0){
          if(!this.$route.query.orderId){
            this.notifyInfo(`The order: ${this.search} doesn't have any tracking data.`)
          }
        }
      })
      .catch(error => {
        console.log(error)
      })
      .finally(() => {
          this.isBusy = false;
        }
      );
    },
    formatDateAssigned(value) {
      return new Date(value).toLocaleString('en-US', { hour12: false });
    }
  },
  data() {
    return {
      isBusy: false,
      showSearch: true,
      search: '',
      fields: [
        {
          key: 'dateTime',
          label: 'Date',
          sortable: true,
          filterByFormatted: true, 
          formatter: "formatDateAssigned" 
        },
        {
          key: 'location',
          label: 'Location',
          sortable: true,
          filterByFormatted: true
        },
        {
          key: 'remark',
          label: 'Remark',
          sortable: true,
          filterByFormatted: true
        }
      ],
      items: []
    }
  }
}
</script>
<style>

</style>