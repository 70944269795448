<template>
  <div>
    <!--    <div class="row container-fluid text-muted">-->

    <b-row>
      <b-col cols="3" class="ml-3 mt-3">
        <b-form-group
            label="Start Date"
            label-for="start-date"
            label-cols-sm="3"
            label-align-sm="left"
        >
          <b-form-datepicker
              id="start-date"
              size="sm"
              :max="end"
              v-model="start"
              :date-format-options="{
                year: 'numeric',
                month: 'short',
                day: '2-digit',
                weekday: 'short',
              }"
          ></b-form-datepicker>
        </b-form-group>
      </b-col>
      <b-col cols="3" class="mt-3">
        <b-form-group
            label="End Date"
            label-for="end-date"
            label-cols-sm="3"
            label-align-sm="left"
        >
          <b-form-datepicker
              id="end-date"
              size="sm"
              :min="start"
              v-model="end"
              :date-format-options="{
                year: 'numeric',
                month: 'short',
                day: '2-digit',
                weekday: 'short',
              }"
          ></b-form-datepicker>
        </b-form-group>
      </b-col>
    </b-row>
    










    
    <!--    Alert-->
    <b-alert :show="dismissCountDown"
             dismissible
             variant="danger"
             @dismissed="dismissCountDown=0"
             @dismiss-count-down="countDownChanged"
             class="fixed-top w-25 mx-auto">
      {{ alertError }}
    </b-alert>

      <b-row>
        <b-col class="col-sm-3 mt-1 col-1">
          <button
            type="button"
            class="btn btn-primary mr-2"
            @click="getQuotes"
          >
            Search
          </button>
          <button
            type="button"
            class="btn btn-primary mr-2"
            @click="clearFilters"
          >
            Clear Filters
          </button>
        </b-col>
        <b-col cols="auto" class="d-inline-flex ml-auto">
          <b-pagination
              class="ml-3"
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              aria-controls="my-table"
          />
          <div class="ml-3 d-inline-flex">
            <label for="perPageInput" class="col-form-label text-muted">Quotes per page</label>
            <input
                class="form-control col-3 ml-2"
                type="number"
                v-model="perPage"
                id="perPageInput"
            />
            <label for="" class="ml-1 col-form-label text-muted">Max 50 results returned</label>
          </div>
        </b-col>
      </b-row>
    <!--Table-->
    <div class="row mx-auto no-gutters">
      <b-table sticky-header="600px" fixed responsive striped hover bordered
               :items="items"
               :fields="fields"
               :current-page="currentPage"
               :per-page="perPage"
               :busy="isBusy"
               :filter="filter"
               :filter-included-fields="filterOn"
               @filtered="onFiltered">

        <!--Carrier Details-->
        <template #cell(carrier_details)="row">
          <b-button size="sm" @click="row.toggleDetails" class="mr-2">
            {{ row.detailsShowing ? 'Hide' : 'Show' }} Details
          </b-button>
        </template>
        <template #row-details="row">
          <Rates :id="row.item.orderID"/>
        </template>
        <!--Table loading spinner/label-->
        <template v-if="isBusy" #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Retrieving...</strong>
          </div>
        </template>
      </b-table>
    </div>
  </div>
  <!--    </div>-->
</template>

<script>
import QuoteService from "@/common/quotes.service";
import Rates from "@/components/Rates";

export default {
  components: {Rates},
  mounted() {
    if(this.$route.query.orderId){
      this.orderId = this.$route.query.orderId;
    }
    var start = this.$route.query.start;
    var end = this.$route.query.end;
    if(start || end ){
      this.start = start;
      this.end = end;
    }
    this.getQuotes()
  },
  computed: {
    rows() {
      return this.items.length
    },
  },
  methods: {
    clearFilters(){
      this.start = '';
      this.end = '';
      this.orderId = '';
    },
    getQuotes() {
      this.isBusy = true;
      QuoteService.getQuotes(
          this.start,
          this.end,
          this.orderId
      )
      .then(quotes => {
        this.items = quotes;
        this.isBusy = false
      }).catch(error => {
        console.log(error)

        this.dismissCountDown = this.dismissSecs
        this.alertError = error

      }).finally(() => this.isBusy = false);
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    }
  },
  data() {
    return {
      start: '',
      end: '',
      isBusy: true,
      orderId: '',
      currentPage: 1,
      perPage: 10,
      totalRows: 1,
      filter: null,
      filterOn: [],
      dismissSecs: 8,
      dismissCountDown: 0,
      alertError: null,
      fields: [
        {
          key: 'orderID',
          label: 'Quote Number',
          sortable: true,
          filterByFormatted: true
        },
        {
          key: 'shipperName',
          label: 'Origin',
          sortable: true,
          filterByFormatted: true
        },
        {
          key: 'shipperCity',
          label: 'Origin City',
          sortable: true,
          filterByFormatted: true
        },
        {
          key: 'shipperState',
          label: 'Origin State',
          sortable: true,
          filterByFormatted: true
        },
        {
          key: 'shipperZip',
          label: 'Origin Zip',
          sortable: true,
          filterByFormatted: true
        },
        {
          key: 'consigneeName',
          label: 'Destination',
          sortable: true,
          filterByFormatted: true
        },
        {
          key: 'consigneeCity',
          label: 'Dest City',
          sortable: true,
          filterByFormatted: true
        },
        {
          key: 'consigneeState',
          label: 'Dest State',
          sortable: true,
          filterByFormatted: true
        },
        {
          key: 'consigneeZip',
          label: 'Dest Zip',
          sortable: true,
          filterByFormatted: true
        },
        'carrier_details'
      ],
      items: []
    }
  }
}
</script>
<style>

</style>
