<template>
  <div>
    <div class="card mx-5 bg-warning">
      <div v-html="legalText" /> 
    </div>
    <div class="mt-3 form-group row mt-5 justify-content-center">
      <b-pagination  class="ml-3"
                    v-model="currentPage"
                    :per-page="perPage"
                    :total-rows="rows"
                    aria-controls="rate-table"
      ></b-pagination>
      <label for="perPageInput" class="ml-5 col-form-label text-muted">Rates per page</label>
      <div class="col-1">
        <input class="form-control" type="number" v-model="perPage" id="perPageInput">
      </div>
    </div>
    <div v-if="isBusy" class="text-center text-primary my-2">
      <b-spinner class="align-middle"></b-spinner>
      <strong>{{message}}...</strong>
    </div>
    <div class="row mx-auto no-gutters w-50" v-if="!isBusy">
      <b-table fixed striped hover borderless
               :items="rates"
               :fields="fields"
               :current-page="currentPage"
               :per-page="perPage">
      <!--selectable-->
      <template #cell(carrier_name)="row" class="">
        <input class="mr-2" type="radio" id="rateAccept" name="rateAccept" :value="row.item.quote_number" v-model="selectedRate" :disabled="row.item.showWarning">
        <label :for="row.item.quote_number"> {{row.item.carrier_name}}</label>
      </template>
        
      <!--selectable-->
      <template #cell(total)="row" class="">
        <label v-if="row.item.showWarning == false"> {{row.item.total}}</label>
      </template>
      <template #cell(transit_days)="row" class="">
        <label v-if="row.item.showWarning == false"> {{row.item.transit_days}}</label>
        <div style="white-space: nowrap;" v-if="row.item.showWarning">
          Freight dimensions exceed the LTL carrier’s cubic capacity standards. Please
        </div>
        <div style="white-space: nowrap;" v-if="row.item.showWarning">
          contact your England Logistics account manager or agent for volume pricing
        </div>
      </template>
      <!--Carrier Details-->
      <template #cell(carrier_details)="row">
        <b-button v-if="row.item.showWarning == false" size="sm" @click="row.toggleDetails" class="mr-2">
          {{ row.detailsShowing ? 'Hide' : 'Show' }} Details
        </b-button>
        <span v-if="row.item.showWarning == true" >
                                         
        </span>
      </template>
      <template #row-details="row">
        <b-row class="mb-2" v-for="(item, index) in row.item.line_items" v-bind:key="index">
            <b-col sm="2"></b-col>
            <b-col sm="3">
              <span>{{item.description}} </span>
            </b-col>
            <b-col sm="3">
                <b-form-input
                  :value="parseFloat(item.amount,2).toFixed(2)"
                  readonly
                ></b-form-input>
            </b-col>
          </b-row>
      </template>
      <template v-slot:custom-foot="">
        <div v-if="rates.length > 0" class="mt-2">
          <button
            type="button" 
            class="btn btn-primary mr-2"
            @click="acceptCarrierRate"
            :disabled="checkDate()"
            :title="getTitle()"
          >
            Accept Rate
          </button>
          <button
            type="button"
            class="btn btn-primary mr-2"
            @click="goBack"
          >
            Back to Quote
          </button>
        </div>
      </template>
      <template #table-caption  v-if="rates.length == 0 && !isBusy">There was a problem retrieving quotes. 
          <a
            @click="getAllData"
            class="btn btn-primary mr-2"
          >
            Try Again
          </a>
          
          <button
            type="button"
            class="btn btn-primary ml-2"
            @click="goBack"
          >
            Back to Quote
          </button>
      </template>
      </b-table>
    </div>
    
  </div>
</template>
<script>
import QuoteService from "@/common/quotes.service";
import LegalService from "@/common/legal.service";
import OrderService from "@/common/orders.service";

export default {
  props: {
    id: Number
  },
  computed: {
    rows() {
      return this.rates.length
    }
  },
  mounted() {
    this.orderId = this.$route.query.orderId;
    if(!this.orderId){
      this.orderId = this.id;
    }
    this.getAllData();
  },
  data() {
    return {
      totals: {},
      message: 'Retrieving',
      orderId: 0,
      legalText: '',
      isBusy: false,
      currentPage: 1,
      perPage: 10,
      selectedRate: '',
      tableType: 'Review',
      fields: [
        {
          key: 'carrier_name',
          label: 'Carrier',
          sortable: true,
          class: 'extrabigColumn',
        },
        {
          key: 'transit_days',
          label: 'Transit Days',
          sortable: true,
          class: 'regularColumn'
        },
        {
          key: 'total',
          label: 'Total Charge',
          sortable: true,
          formatter: 'decimals',
          class: 'regularColumn'
        },
        {
          key: 'carrier_details',
          class: 'bigColumn',
        },
        
      ],
      rates: []
    }
  },
  methods: {
    getTitle(){
      if (this.checkDate()){
        return 'Rates expired';
      }
      return '';
    },
    checkDate(){
      var pickup_late = new Date(this.order.shipping_schedule.pickup_late.replace('Z','').substring(0,16));
      let pastHour = new Date();
      pastHour.setTime(pastHour.getTime() + 1 * 60 * 60 * 1000);
      return pickup_late < pastHour;
    },
    acceptCarrierRate(){
      if(this.selectedRate !== ''){
        if(this.validate()){
          this.isBusy = true,
          this.message = `Order ${this.orderId} booking in process`;
          var newOrderId = this.orderId;
          QuoteService.acceptCarrierRate(this.selectedRate)
            .then(() => {
              this.$router.push({ name: 'order', query: { orderId: newOrderId } })
            })
            .catch(() => {
              this.notifyError("There was an error selecting the rate")
            })
            .finally(() => {
              this.isBusy = false;
            });
        }
        else{
          this.$router.push({ name: 'quote', query: { orderId: this.orderId } , params: {selectedRate: this.selectedRate}}); 
        }
      }
    },
    validate(){
      var isValid = true;
      this.order.freight_details.products.forEach(product => {
        if (!product.description){
          //this.notifyError("All product should have a description, please review the quote")
          isValid = false;
        }
        if(product.hazmat_toggle) {
          if (!product.hazmat_details.contact ||
              !product.hazmat_details.emergency_phone ||
              !product.hazmat_details.hazmatClass ||
              !product.hazmat_details.hazmatUnnaNbr ||
              !product.hazmat_details.hazmatContactType ||
              !product.hazmat_details.shipping_name){
            isValid = false;
          }
        }
      });
      if(!this.order.origin?.location?.name
        || !this.order.origin?.location?.address_1 
        || !this.order.origin?.contact_name
        || !this.order.origin?.contact_phone 
        ){
          //this.notifyError(!this.order.origin?.location?.address_1 ? "Origin Adress 1 is required to book an order" : "");
          //this.notifyError(!this.order.origin?.contact_name ? "Origin Contact Name is required to book an order" : "");
          //this.notifyError(!this.order.origin?.contact_phone ? "Origin Phone is required to book an order" : "");
          isValid = false;
        }
      if(!this.order.destination?.location?.name 
        || !this.order.destination?.location?.address_1
        || !this.order.destination?.contact_name
        || !this.order.destination?.contact_phone
        ){
          //this.notifyError(!this.order.destination?.location?.address_1 ? "Destination Adress 1 is required to book an order" : "");
          //this.notifyError(!this.order.destination?.contact_name ? "Destination Contact Name is required to book an order" : "");
          //this.notifyError(!this.order.destination?.contact_phone ? "Destination Phone is required to book an order" : "");
          isValid = false;
        }
      return isValid;
    },
    goBack(){
      this.$router.push({ name: 'quote', query: { orderId: this.orderId } }); 
    },
    getAllData(){
      this.isBusy = true;
      if (this.orderId) {
        OrderService.getOrderByID(this.orderId)
        .then(data => {        
          this.order = data.order; 
          this.getCarrierData();       
        });
      }
      LegalService.getLegal()
      .then(text => {
        this.legalText = text;
      });
    },
    getCarrierData(){
      this.isBusy = true;
      QuoteService.getCarrierRates(this.orderId)
        .then(rates => {
          rates.sort((a, b) => {
              return a.total - b.total;
          });
          var carriers = [];
          
          rates.forEach((rate) => {
            carriers.push(rate.carrier_scac.trim());
          });
          QuoteService.getCarrierRatesRestrictions(carriers)
            .then(restrictions => {        
              rates.forEach((rate) => {
                rate.showWarning = false;
                var carrierRestriction = restrictions.filter(f => f.scac.trim() === rate.carrier_scac.trim())[0];
                if(!carrierRestriction.cubicWarning && !carrierRestriction.densityWarning ) {
                  carrierRestriction.cubicWarning = 750;
                  carrierRestriction.densityWarning = 6;
                }
                var total_density = 0;
                var total_cubic = 0;
                var total_weight = 0;
                this.order.freight_details.products.forEach((product) => {
                  if (product) {
                    var height = product.height;
                    var width = product.width;
                    total_weight+= product.weight;
                    if (carrierRestriction.heightOverrideX && height > carrierRestriction.heightOverrideX){
                      height = carrierRestriction.heightOverrideY;                    
                    }
                    if (carrierRestriction.widthOverrideX && width > carrierRestriction.widthOverrideX){
                      width = carrierRestriction.widthOverrideY;                    
                    }
                                
                    if(product.length){
                      product.linear_units = Math.round(product.length / 12 * product.handling_units);
                    }
                    if(product.length && width && height){
                      product.cubic_units = Math.round(((product.length * width * height) / 1728) * product.handling_units);
                    }
                    if (product.cubic_units && product.cubic_units !== 0) {
                      product.density_units = parseFloat(
                        product.weight / product.cubic_units,
                        4
                      ).toFixed(4);
                    }
                    total_density += parseFloat(product.density_units);
                    total_cubic += product.cubic_units;
                  }
                });
                total_density = total_weight / total_cubic;
                if(window.__env.api.apiUrl.indexOf('staging')){
                  console.log(`Carrier restrictions ${JSON.stringify(carrierRestriction)}, total density: ${total_density}, total cubic: ${total_cubic}`);
                }
                if( total_density < carrierRestriction.densityWarning  && total_cubic > carrierRestriction.cubicWarning ){
                  rate.showWarning = true;
                }
                if(carrierRestriction.cubicWarning2 && carrierRestriction.densityWarning2 ) {
                  if( total_density < carrierRestriction.densityWarning2  && total_cubic > carrierRestriction.cubicWarning2 ){
                    rate.showWarning = true;
                  }
                }
                this.rates = rates
              });
            });
        })
        .catch(() => {
          this.notifyError("There was an error retrieving rates")
        })
        .finally(() => {
          this.isBusy = false;
        });
    },
    decimals(value){
      return value.toFixed(2);
    },
    
  }
}
</script>
<style>
.extrabigColumn{
  width: 380px;
}
.bigColumn{
  width: 230px;
}
.regularColumn{
  width: 170px;
}
</style>