<template>
  <div>
    <ViewIdentity name="Documents"></ViewIdentity>
    <div class="col d-inline-flex justify-content-center">
      <div class="card p-3 w-50">
      <form v-on:submit.prevent="this.getDocument">
        <label for="order-number">Input an order number</label>
        <input class="form-control" id="order-number" placeholder="Order Number" v-model="orderNumber"/>
        <label for="document-type">Select a document type</label>
        <select class="form-control" name="documentType" id="document-type" v-model="documentType">
          <option v-for="(option, index) in options"  v-bind:key="index" v-bind:value="option.id">{{ option.description }}</option>
        </select>
        <button class="float-right btn btn-primary mt-1">Load</button>
      </form>
        <b-alert :show="dismissCountDown"
                 dismissible
                 variant="danger"
                 @dismissed="dismissCountDown=0"
                 @dismiss-count-down="countDownChanged"
                 class="fixed-top w-25 mx-auto">
          {{ alertError }}
        </b-alert>
      </div>
    </div>
  </div>
</template>

<script>
import ViewIdentity from "@/components/ViewIdentity";
import DocumentService from "@/common/documents.service";

export default {
  name: "Documents",
  components: {ViewIdentity},
  mounted() {
    this.getOptions();
  },
  data() {
    return {
      orderNumber: "11752221",
      documentType: "",
      options: [],
      dismissSecs: 8,
      dismissCountDown: 0,
      showAlert: false,
      alertError: null,
    }
  },
  methods: {
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },
    getOptions() {
      DocumentService.getOptions(this.orderNumber)
      .then(res => {
        this.options = res;
      }).catch(error => {
        console.log(error)

        this.dismissCountDown = this.dismissSecs
        this.alertError = error

      }).finally(() => this.showAlert = true);
    },
    getDocument() {
      // TODO: load the document from the API
    }
  }
}
</script>

<style scoped>

</style>