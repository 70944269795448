<template>
  <div class="col m-1 card m-3 p-4 w-50">
    
    <div class="form-row">
      <div class="col-md-6">
        <h5 class="text-primary mb-3">{{label}}</h5>
      </div>
      <div class="col-md-6">
        <div class="float-right">
          <input
            tabindex="-1"
            class="form-check-input checkbox mt-2"
            type="checkbox"
            v-model="saveLocation"
            :disabled ="readonly"
          />
          <label class="form-check-label mr-4">
            Save location
          </label>
          <input
            tabindex="-1"
            class="form-check-input checkbox mt-2"
            type="checkbox"
            v-model="setDefault"
            :disabled ="readonly"
          />
          <label class="form-check-label">
            Set as default
          </label>
          <button v-if="!readonly" tabindex="-1"
            class="btn btn-primary ml-2 deleteButton"
            title="Clear Location"
            @click="() => {setLocation();}"
          > X 
          </button>
        </div>
      </div>
    </div>
    <div class="form-row">
      <LocationSearchBar
        class="form-group col-md-12"
        :label="label"
        :location-selected="setLocation"
        v-model="location.name"
        :requiredId="required.id"
        @checkValidity="checkValidity"
        @inputUpdate="inputUpdate"
        :readonly="readonly"
        :canDelete="false"
        :showValidationsAcceptQuote="showValidationsAcceptQuote"
        :saveLocation="saveLocation"
        :showValidations="showValidations"        
      />
    </div>
    <label >Address
    </label> 

    <b-collapse :id="'collapse-location'" collapse ="my-accordion" role="tabpanel">
      <div class="form-group">
        <b-form-input
          type="text"
          class="form-control"
          placeholder="1234 Main St"
          v-model="location.address_1"
          :maxlength="40"
          :readonly="readonly"
          :state="isValidToAcceptQuote(location.address_1)"
        />
      </div>
      <div class="form-group">
        <label>Address 2</label>
        <b-form-input
          type="text"
          class="form-control"
          placeholder="Apartment, studio, or floor"
          v-model="location.address_2"
          :maxlength="40"
          :readonly="readonly"
        />
      </div>
    </b-collapse>
    <div class="form-row">
      <div class="form-group col-md-6">
        <label>City <span class="text-danger font-weight-bold">*</span></label>
        <b-spinner small class="align-middle ml-3" v-if="isBusy"></b-spinner>
        <b-form-input
          type="text"
          class="form-control"
          v-model="location.city"
          :state="isValid(location.city)"
          required
          @blur="showCities=false"
          @keyup="getCities()"
          autocomplete="nope" 
          @focus="showCities = true"
          :readonly="readonly"
        />
        
        <ul v-if="!readonly"
          class="list-group position-absolute zIndex scrollable"
          v-show="showCities"
        >
          <li 
            class="list-group-item list-group-item-action p-3"
            v-for="(item, index) in cities"
            v-bind:key="index"
            @mousedown="selectCity(item)"
          >
            <span>{{ item['name'] }} 
              <span class="ml-1">{{ item['state'] }} </span>
            </span>
            <div>
              <span class="badge badge-primary float-right">{{item['zip_code']}}</span>
            </div>
          </li>
          <li 
            class="list-group-item list-group-item-action p-3"
            v-if="cities.length === 0 && searchOnce">
            <span>Please enter a valid city, state, and zipcode to continue</span>
          </li>

        </ul>
      </div>
      <div class="form-group col-md-4">
        <label>State <span class="text-danger font-weight-bold">*</span></label>
        <b-form-input
          type="text"
          class="form-control"
          v-model="location.state"
          :state="isValid(location.state)"
          required
          @blur="show=false"
          @keyup="getCities()"
          autocomplete="nope" 
          @focus="showCities = true"
          :readonly="readonly"
        />
      </div>
      <div class="form-group col-md-2">
        <label>Zip <span class="text-danger font-weight-bold">*</span></label>
        <b-form-input
          type="text"
          v-model="location.zip"
          :state="isValid(location.zip)"
          @keyup="getCities()"
          required
          @blur="showCities=false"
          autocomplete="nope" 
          @focus="showCities = true"
          :readonly="readonly"
        />
      </div>
    </div>
    <div class="form-row">
      <div class="col-md-6">
        <label>Contact Name
        </label> 
        <b-collapse :id="'collapse-location'" collapse ="my-accordion" role="tabpanel">
          <b-form-input
            type="text"
            class="form-control"
            v-model="contact_name"
            :readonly="readonly"
            :maxlength="40"
            :state="isValidToAcceptQuote(contact_name)"
          />
        </b-collapse>
      </div>
      <div class="col-md-6">
        <b-collapse :id="'collapse-location'" collapse ="my-accordion" role="tabpanel">
          <label>Phone</label>
          <b-form-input
            type="text"
            class="form-control"
            v-model="contact_phone"
            @keyup="acceptNumber"
            :readonly="readonly"
            :state="isValidToAcceptQuote(contact_phone)"
          />
        </b-collapse>
      </div>
    </div>
  </div>
</template>

<script>
import LocationSearchBar from "@/components/LocationSearchBar";
import CityService from "@/common/city.service";

export default {
  label: "",
  props: {
    label: String,
    showValidations: Boolean,
    showValidationsAcceptQuote: Boolean,
    readonly: Boolean,
    dataLoaded: Boolean
  },
  components: { LocationSearchBar },
  emits: ["onChange"],
  mounted() {
  },
  data() {
    return {
      location: {
        id: "",
        name: "",
        address_1: "",
        address_2: "",
        city: "",
        state: "",
        zip: "",
      },
      required: {
        id: null,
        city: null,
        state: null,
        zip: null,
      },
      saveLocation: false,
      setDefault: false,
      contact_name: "",
      contact_phone: "",
      stop_id: null,
      cities: [],
      showCities: false,
      isBusy: false,
      debounce: null,
      searchOnce: false
    };
  },
  methods: {
    setLocation: function(location) {
      this.required.id = null;
      if(location){
        if(location.location){
          location = location.location;
        }
        this.location = location;
        this.contact_name = location.contact_name;
        this.contact_phone = location.contact_phone;
        this.stop_id = location.stop_id;
      }
      else{
        this.location = {
          address_1: '',
          address_2: '',
          city: '',
          id: '',
          name: '',
          state: '',
          zip: '',
        };
        this.saveLocation= false;
        this.setDefault= false;
        this.contact_name= "";
        this.contact_phone= "";
        this.stop_id= null;
        this.cities= [];
        this.showCities= false;
        this.isBusy= false;
        this.debounce= null;
      }
    },

    checkValidity() {
      if (!this.location.id) this.required.id = false;
      else this.required.id = null;
    },
    inputUpdate(val) {
      this.location.location_id ='';
      this.location.name = val;
    },
    isValid(value) {
      if((this.searchOnce || this.showValidations) && !value){
        return false;
      }
      if(this.searchOnce && this.cities && this.cities.length === 0){
        return false;
      }
      return null;
    },
    isValidToAcceptQuote(value){
      if (this.showValidationsAcceptQuote && !value) {
        return false;
      }
      if (this.showValidationsAcceptQuote && value.trim() === '') {
        return false;
      }
       
      if (this.saveLocation && this.showValidations && !value){
        return false;
      }
      return null;
    },
    getCities(){
      //if(this.location.zip !== '')
      {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.isBusy = true;
        CityService.getCities(
          this.location.city,
          this.location.state,
          this.location.zip,
        )
        .then(cities => {
          this.searchOnce = true;
          if(cities){
            this.cities = cities;
            this.showCities =true;
            this.isBusy = false;
            if(cities.length === 1)
            {
              this.selectCity(cities[0]);
            }
          }
        });
      }, 600)
      }
    },
    selectCity(city){
      if(city){
        this.location.city = city.name;
        this.location.state = city.state;
        this.location.zip = city.zip_code;
        this.location.id = city.city_id;
      }
      this.showCities = false;
      this.isBusy = false;
    },
    acceptNumber() {
      var x = this.contact_phone.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})(\d{0,5})/);
      this.contact_phone = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '') + (x[4] ? 'x' + x[4] : '');
    }
  },
  watch: {
    'location' : {
      handler: function() {
      },
      deep: true,
    },
    $data: {
      handler: function(newVal) {
        this.$emit("onChange", newVal);
      },
      deep: true,
    },
    dataLoaded:{
      handler: function(value) {
        if(value)
        {
          if(!this.location.zip){    
            let user = JSON.parse(localStorage.getItem("user"));
            // see if we already have a value saved for this label (set as default) and load it
            let item = localStorage.getItem("defaultLocation" + this.label + user.email_address);
            if (item) {
              let data = JSON.parse(item);
              this.location = data.location;
              this.saveLocation = data.saveLocation;
              this.setDefault = data.setDefault;
              this.contact_name = data.contact_name;
              this.contact_phone = data.contact_phone;
            }
          }
        }
      },
      deep: true,
    }
  },
};
</script>

<style scoped>
  .zIndex {
    z-index: 1001;
  }
  .scrollable {
    width: 95%;
    height: auto;
    max-height: 300px;
    text-align: left;
    overflow-x: hidden;
    overflow-y: auto;
    box-shadow: 4px 4px 7px -2px #cecece;
    border-radius: 0;
  }
  .checkbox{
    min-width: 16px;
    min-height: 16px;
  }
  .deleteButton{
    min-width: 42px;
  }
</style>
