<template>
  <div class="card p-1">
    <div class="card embed-responsive">
      <div class="mt-2 p-2"  v-if="!isBusy">
        <div class="row">
          <div class="col-sm-4">
            <h6><i class="fa fa-map-marker text-primary"></i> Pending Assignment</h6>
          </div>
          <div class="col-sm-4">
            <h6><i class="fa fa-map-marker text-secondary"></i>To Be Picked Up</h6>
          </div>
          <div class="col-sm-4">
            <h6><i class="fa fa-map-marker text-success"></i>In Transit</h6>
          </div>
        </div>
        <div class="toggle">
          <label :class="mapOption ==='Origin' ? 'selected' : ''" @click="updateMap('Origin')">
            <div>Origin</div>
          </label>
          <label :class="mapOption ==='Destination' ? 'selected' : ''" @click="updateMap('Destination')">
            <div>Destination</div>
          </label>
          <label :class="mapOption ==='Tracking' ? 'selected' : ''" @click="updateMap('Tracking')">
            <div>Tracking</div>
          </label>
        </div>
      </div>
      <div class="text-center text-primary mt-5 mb-3 p-2" v-if="isBusy">
        <b-spinner class="align-middle"></b-spinner>
        <strong>Retrieving...</strong>
      </div>
      <div style="height: 45vh;">
        <l-map
          style="height: 40vh;"
          ref="map"
          :zoom="zoom"
          :center="center"
          :options="mapOptions"
          @update:zoom="zoomUpdate"
        >
          <l-tile-layer
            :url="url"
            :attribution="attribution"
          />
          <div v-for="(marker) in allMarkers" v-bind:key="marker.order_id">
            <l-marker :lat-lng="getCoordenates(marker.latitude, marker.longitude)" :icon="getIcon(marker)" @click="clickMarker">
              <l-popup>
                <div v-for="(item, index) in marker.markersSameLocation" v-bind:key="item.order_id">
                  <div v-if="index == currentIndex || marker.markersSameLocation.length <= 1" class="minWidth">
                    Order Id: <a href="#" @click="openOrder(item.order_id)">{{item.order_id}} </a>
                    <div v-show="marker.markersSameLocation.length > 1" class="float-right">
                      <i class="fa fa-angle-left ml-5" @click="decrease()"></i>
                      <i class="fa fa-angle-right ml-1" @click="increase(marker)"></i>
                    </div>
                    <p>
                      Status: {{item.map_status}} <br> 
                      Origin: {{item.originCity}} <br> 
                      Destination: {{item.destinationCity}} <br> <br> 
                      Schedule Pickup <br> 
                      {{item.sched_arrive_early}} <br> 
                      {{item.sched_arrive_late}}  <br>  <br> 
                      Last Track <br> 
                      <span v-if="item.lastTrack">
                        Location: {{item.lastTrackLocation}}<br> 
                        Date Time: {{item.lastTrackDateTime}}<br> 
                        Note: {{item.lastTrack}}
                      </span>
                    </p>
                  </div>
                </div>
              </l-popup>
            </l-marker>
          </div>
        </l-map>
      </div>
      
    </div>
  </div>
</template>
<style>
.active-cyan input.form-control[type=text] {
  border-bottom: 1px solid #4dd0e1;
  box-shadow: 0 1px 0 0 #4dd0e1;
}

.toggle {
  width:100%;
  box-sizing:border-box;
  background-color:white;
  text-align:center;
  position:relative;
  border-radius:2px;
}

.toggle label {
  float:left;
  width:calc(33.333% - 1px);
  position:relative;
  padding:5px 0px 30px;
  overflow:hidden;
  border:solid 1px rgba(0,0,0,0.2);
  transition:color 0.3s;
  cursor:pointer;
  margin-bottom: 5px;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

.toggle label input {
  position:absolute;
  top:-200%;
}

.toggle label div {
  z-index: 5;
  position: absolute;
  width: 100%;
}

.toggle label.selected {
  color:white;
  background-color: #2185e3;
}
</style>

<script>
import OrderService from "@/common/orders.service";
import { latLng  } from "leaflet";
import { LMap, LTileLayer, LMarker, LPopup } from "vue2-leaflet";
import L from 'leaflet';

export default {
  name: "Icon",
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPopup
  },
  mounted() {
    this.getMapData();
  },
  data() {
    return {
      currentIndex: 0,
      mapOption: "Origin",
      isBusy: false,
      center: [0, 0],
      zoom: 0,
      allMarkers: [],
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',       
      mapOptions: {
        zoomSnap: 0.5
      }
    };
  },
  methods: {
    clickMarker(){
      this.currentIndex = 0;
    },
    getMapData(){
      this.isBusy = true;
      var map = this.$refs.map;
      OrderService.getMapData(this.mapOption)
      .then((markers) => {
        markers.forEach(item => {
          if(item.sched_arrive_early)
            item.sched_arrive_early = new Date(item.sched_arrive_early).toLocaleString();
            
          if(item.sched_arrive_late)
            item.sched_arrive_late = new Date(item.sched_arrive_late).toLocaleString();

          item.markersSameLocation = this.getMarkersSameLocation(markers, item);
        });
        this.allMarkers = markers;
        var latlngs = this.allMarkers.map(m => {return [m.latitude, m.longitude] });
        map.fitBounds( latlngs );
      })
      .finally(()=> this.isBusy = false );
    },
    getIcon(marker){
      var iconClass ='';
        
      if(marker.markersSameLocation.length > 1){
        var hasBlue = marker.markersSameLocation.some(s=>s.map_status ==="Pending Assignment");
        var hasGreen = marker.markersSameLocation.some(s=>s.map_status ==="In Transit");
        var hasGray = marker.markersSameLocation.some(s=>s.map_status ==="To be Picked Up");
        if(hasGreen){
          iconClass = 'greenIcon';
        }
        if(hasGray){
          iconClass = 'grayIcon';
        }
        if(hasBlue){
          iconClass = 'blueIcon';
        }
        if(hasBlue && hasGreen){
            iconClass = 'iconGreenBlue';
        }
        if(hasGray && hasBlue){
          iconClass = 'iconBlueGray';
        }
        if(hasGray && hasGreen){
          iconClass = 'iconGreenGray';
        }
        
      }
      else{
        if(marker.map_status === "In Transit"){
          iconClass = 'greenIcon';
        }
        if(marker.map_status === "Pending Assignment"){
          iconClass = 'blueIcon';
        }
        if(marker.map_status === "To be Picked Up"){
          iconClass = 'grayIcon';
        }
      }
      
      var newIcon = L.divIcon({
          html: `<i class="fa fa-map-marker ${iconClass} fa-3x"></i>`, 
          iconSize: [20, 20],
          className: 'myDivIcon'
      });

      if(marker.markersSameLocation.length > 1){
        newIcon = L.divIcon({
            html: `<i class="fa fa-map-marker ${iconClass} fa-3x"> <span class="customBadge">${marker.markersSameLocation.length}</span> </i>`, 
            iconSize: [20, 20],
            className: 'myDivIcon'
        });
      }
      if(marker.markersSameLocation.length > 9){
        newIcon = L.divIcon({
            html: `<i class="fa fa-map-marker ${iconClass} fa-3x"> <span class="customBadge2Digits">${marker.markersSameLocation.length}</span> </i>`, 
            iconSize: [20, 20],
            className: 'myDivIcon'
        });
      }

      return newIcon;
    },
    getMarkersSameLocation(markers, currentMarker){
        var results = markers.filter(f=>f.latitude === currentMarker.latitude && f.longitude === currentMarker.longitude);
        return results;
    },
    increase(currentMarker){
        if(this.currentIndex < currentMarker.markersSameLocation.length - 1)
        {
          this.currentIndex++;
        }
    },
    decrease(){
        if(this.currentIndex >= 1)
        {
          this.currentIndex--;
        }
    },
    
    getCoordenates(lat,lng){
      return latLng(lat,lng);
    },
    zoomUpdate(zoom) {
      this.currentZoom = zoom;
    },
    centerUpdate(center) {
      this.currentCenter = center;
    },
    openOrder(orderId){
      this.$router.push({ name: 'order', query: { orderId: orderId } });
    },
    updateMap(type){
      this.mapOption = type;
      this.getMapData();
      
    }
  }
};
</script>

<style>
.minWidth {
  min-width: 220px;
}
.leaflet-popup {
  min-width: 210px!important;
}
.greenIcon{
  color: #28a745;
}
.blueIcon{
  color: #2185e3;
}
.grayIcon{
  color: #6c757d;
}
.iconGreenBlue:before {
    background: linear-gradient(to right, #28a745 0%, #28a745 50%, #2185e3 50%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.iconGreenGray:before {
    background: linear-gradient(to right, #28a745 0%, #28a745 50%, #6c757d 50%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.iconBlueGray:before {
    background: linear-gradient(to right, #2185e3 0%, #2185e3 50%, #6c757d 50%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.customBadge {
    position: fixed;
    text-align: left;
    font-size: 1rem;
    border-radius: 50%;
    color: white;
    margin-left: -17px;
    margin-top: 5px;
}
.customBadge2Digits {
    position: fixed;
    text-align: left;
    font-size: 1rem;
    border-radius: 50%;
    color: white;
    margin-left: -21px;
    margin-top: 5px;
}

</style>