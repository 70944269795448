<template>
  <nav class="maximum shadow navbar navbar-expand-lg bg-light">
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="hidden-xs-down navbar-nav mr-auto">
        <button type="button" class="btn btn-secondary mr-2 ml-2" @click="menuClicked">
          <i class="fa fa-bars"></i>
        </button>
        <search-bar class="mt-1 "></search-bar>
        <search-bar class="ml-5 mt-1 " :isShipment="true"></search-bar>
      </ul>
      <ul class="navbar-nav ml-2">
        <li class="form-inline my-2 my-lg-0">
              <span v-if="username" class="navbar-text">
                <strong>{{ company_name }} -</strong> Welcome {{ username }} 
              </span>
          <div type="button" v-on:click="logout" class="ml-4 bg-light text-muted navbar-expand"><i
              class="fa fa-sign-out-alt pr-1"></i>Logout
          </div>
        </li>
      </ul>
    </div>
  </nav>
</template>
<script>
import SearchBar from "@/components/SearchBar";
import OrderService from "@/common/orders.service";

export default {
  components: {SearchBar},
  data: function () {
    var startDate = new Date();
    //Default display last 90 days
    startDate.setDate(startDate.getDate() - 90);
    return {
      username: "John Smith",
      company_name: "ABC Company",
      cartTotal: 0,
      trackShipment: "",
      quickSearch: "",
      dismissSecs: 8,
      dismissCountDown: 0,
      start: startDate,
      end: new Date(),
    }
  },
  mounted() {
    let user = JSON.parse(localStorage.getItem("user"))
    this.username = user.name;
    this.company_name = user.company_name;

    //this.getQuoteCount();
  },
  methods: {
    getQuoteCount() {
      OrderService.getOrderCounts(
          new Date(this.start),
          new Date(this.end)
      ).then((statuses) => {
        console.log(statuses);
        this.cartTotal = statuses.quote;
      });
    },
    logout() {
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      localStorage.removeItem("broker");

      this.$router.push("login");
    },
    menuClicked: function () {
      this.$root.showSideBar = !this.$root.showSideBar;
      if(this.$root.showSideBar){
        document.getElementById("side-drawer").style.width =  "15vw";
        document.getElementById("main").style.width =  "85vw";
      }
      else{
        document.getElementById("side-drawer").style.width =  "3vw";
        document.getElementById("main").style.width =  "97vw";
      }
      
    }
  },
  watch: {
    start() {
      this.getQuoteCount();
    },
    end() {
      this.getQuoteCount();
    },
  }
}
</script>
<style>
.border-10 {
  border-bottom: #2185C5;
  border-width: 10px;
}
.maximum{
  height: 6vh;
  padding-left: 3px;
  min-height: 47px;
}
</style>