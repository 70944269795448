<template>
  <div>
    <ViewIdentity v-if="!readonly" name="Create LTL Quote"></ViewIdentity>
    <b-overlay :show="isBusy" rounded="lg" opacity="0.6">
    <div class="container-fluid">
      <div class="mx-1 px-4">
        <div class="form-row">
                
          <label class="mt-3" v-b-toggle="'collapse-location'" >
            <div
              class="mt-1 h5 text-primary"
              :class="[showAddress ? null : 'collapsed']"
              :aria-expanded="showAddress ? 'true' : 'false'"
              aria-controls="collapse-1"
            >
              <i
                @click="showAddress = !showAddress"
                :class="showAddress ? collapsedIcon : collapseIcon"
                role="button"
                ref="showAddressButton"
              ></i>
            </div>
          </label> 
          <FormLocation
            label="Origin"
            :showValidations="showValidations"
            :showValidationsAcceptQuote="showValidationsAcceptQuote"            
            @onChange="origin = $event"
            ref="locationOrigin"
            :readonly="readonly"
            :dataLoaded="dataLoaded"
          />
          <FormLocation
            label="Destination"
            :showValidations="showValidations"
            :showValidationsAcceptQuote="showValidationsAcceptQuote"
            @onChange="destination = $event"
            ref="locationDestination"
            :readonly="readonly"
            :dataLoaded="dataLoaded"
          />
        </div>
        <div
          class="mt-1 h5 text-primary"
          :class="[showShipSchedule ? null : 'collapsed']"
          :aria-expanded="showShipSchedule ? 'true' : 'false'"
          aria-controls="collapse-1"
        >
          <i
            @click="showShipSchedule = !showShipSchedule"
            :class="showShipSchedule ? collapsedIcon : collapseIcon"
            role="button"
          ></i>
          Shipping Schedule
        </div>
        <b-collapse class="form-row" id="collapse-1" v-model="showShipSchedule">
          <div class="col m-1">
            <div class="form-row">
              <div class="form-group col-md-4">
                <label for="pickupDate">Pickup Date</label>

                <b-datepicker
                  v-model="shippingSchedule.pickup_date"
                  :first-day-of-week="1"
                  placeholder="Click to select..."
                  :readonly="readonly"
                >
                </b-datepicker>
              </div>
              <div class="form-group col-md-4">
                <label for="earlyPickup">Pickup Early</label>
                <b-form-input
                  id="earlyPickup"
                  type="time"
                  v-model="shippingSchedule.pickup_early"
                  :state="pickup_early_state"
                  :readonly="readonly"
                ></b-form-input>
              </div>
              <div class="form-group col-md-4">
                <label for="latePickup">Pickup Late</label>
                <b-form-input
                  id="latePickup"
                  type="time"
                  v-model="shippingSchedule.pickup_late"
                  :state="pickup_late_state"
                  :readonly="readonly"
                ></b-form-input>
              </div>
            </div>
          </div>
        </b-collapse>
        <div
          class="mt-1 h5 text-primary"
          :class="showFreightSummary ? null : 'collapsed'"
          :aria-expanded="showFreightSummary ? 'true' : 'false'"
          aria-controls="collapse-2"
        >
          <i
            @click="showFreightSummary = !showFreightSummary"
            :class="showFreightSummary ? collapsedIcon : collapseIcon"
            role="button"
          ></i>
          Freight Details
          <button v-if="!readonly && showFreightSummary"
            class="btn btn-secondary btn-sm"
            @click="addProduct"
            tabindex="-1"
          >
            Add Another Product
          </button>
        </div>

        <b-collapse
          class="form-row"
          id="collapse-2"
          v-model="showFreightSummary"
        >
          <div class="col m-2">
            <ul class="list-group">
              <div
                class="list-group-item"
                v-for="(product, index) in freightDetails.products"
                v-bind:key="index"
              >
                <div class="form-row">
                    <custom-select
                      class="col-md-2"
                      label="Product"
                      placeholder="Product"
                      v-model="product.description"
                      :items="locationProducts"
                      :item-selected="(item) => loadProductDetails(index, item)"
                      item-key="description"
                      :hideRequired="true"
                      :stateVal="isValidDescription(product)"
                      :readonly="readonly"
                      :typeAhead="true"
                      :maxlength="40"
                    ></custom-select>
                    <custom-select
                      class="col-md-2"
                      id="fdPackaging"
                      label="Packaging"
                      placeholder="Packaging"
                      :typeAhead="true"
                      :validValues="true"
                      :validateExact="true"  
                      v-model="product.packaging"
                      :items="productPackagings"
                      :item-selected="(item) => {}"
                      item-key="field_code"
                      item-key2="field_code_desc"                      
                      :stateVal="isValid(product.packaging)"
                      :readonly="readonly"
                    ></custom-select>
                    <div class="form-group col-md-1">
                      <label for="fdPieces">Pieces <span class="text-danger font-weight-bold">*</span></label>
                      <b-form-input
                        type="text"
                        v-integer.unsigned
                        class="form-control"
                        id="fdPieces"
                        :state="isValidNumber(product.pieces)"
                        required
                        v-model="product.pieces"
                        :readonly="readonly"
                        :maxlength="7"
                      />
                    </div>
                    <div class="form-group col-md-1">
                      <label for="fdWeight">Weight <span class="text-danger font-weight-bold">*</span></label>

                      <b-form-input
                        type="text"
                        class="form-control"
                        id="fdWeight"
                        v-integer.unsigned
                        :state="isValidCubic(product.weight)"
                        required
                        :readonly="readonly"
                        v-model="product.weight"
                        :maxlength="7"
                      />
                    </div>
                    <custom-select
                      class="col-md-1"
                      id="fdClass"
                      label="Class"
                      placeholder="Class"
                      v-model="product.nmfc_class_code"
                      :items="productClases"
                      :validValues="true"
                      :validateExact="true"                      
                      :typeAhead="true"
                      :item-selected="(item) => {}"
                      item-key="field_code"
                      :stateVal="isValid(product.nmfc_class_code)"
                      :requiredVal="true"
                      :readonly="readonly"
                    ></custom-select>
                    <div class="form-group col-md-1">
                      <label for="fdNMFC">NMFC</label>
                      <input
                        type="text"
                        class="form-control"
                        id="fdNMFC"
                        :maxlength="20"
                        :readonly="readonly"
                        v-model="product.nmfc_code"
                      />
                    </div>
                      <div class="form-group form-check" v-if="!readonly">
                        <label class="form-check-label" for="fdSaveProduct">Add To Product List</label>
                        <div class="mt-2">
                          <input class="checkbox" type="checkbox" id="fdSaveProduct" v-model="product.save_product" @change="product.save_product_type = 'Save to Customer Profile'">
                        </div>
                      </div>
                      <div class="form-group col-md-2 row ml-2" v-if="!readonly" v-show="product.save_product">
                        <label class="form-check-label col-md-12" for="fdSaveProduct">Save to</label>
                        <div class="col-md-6">
                          <b-form-radio v-model="product.save_product_type" name="save-product-type" value="Save to Customer Profile">Customer Profile</b-form-radio>
                        </div>
                        <div class="col-md-6">
                          <b-form-radio v-model="product.save_product_type" name="save-product-type" value="Save to Shipping Location">Shipping Location</b-form-radio>
                        </div>
                      </div>
                    <div class="col md-1">
                      <button v-if="!readonly" tabindex="-1"
                        class="btn btn-primary float-right"
                        title="Remove Freight Group Item from Order"
                        @click="() => {removeProduct(index);}">
                        X
                      </button>
                    </div>
                    <div class="form-row">
                      <div class="form-group col-md-1">
                        
                        <label style="white-space:nowrap" for="fdSpots">Spots
                            <span class="text-danger font-weight-bold">*</span>
                            <span class="fa fa-info-circle ml-1" title="This field refers to the total number of trailer floor spaces(48Lx40W) that the freight will occupy"></span>
                        </label>
                        <b-form-input
                          type="text"
                          v-integer.unsigned
                          id="fdSpots"
                          :state="isValidNumber(product.req_spots)"
                          required
                          :readonly="readonly"
                          v-model="product.req_spots"
                          :maxlength="7"
                        />
                      </div>
                      <div class="form-group col-md-2">
                        <label style="white-space:nowrap;" for="fdHandlingUnits">Handling Units
                          <span class="text-danger font-weight-bold">*</span>
                          <span class="fa fa-info-circle ml-1" title="This field is the total number of pallets/crates that the driver will handle. This field should correlate with the dimensions."></span>
                        </label>                        
                        <b-form-input
                          type="text"
                          v-integer.unsigned
                          class="form-control"
                          id="fdHandlingUnits"
                          placeholder="Units"
                          v-model="product.handling_units"
                          :state="isValidCubic(product.handling_units)"
                          required
                          :readonly="readonly"
                          :maxlength="7"
                        />
                      </div>
                      <div class="form-group col-md-1">
                        <label for="fdLength" >Length <span class="text-danger font-weight-bold">*</span></label>
                        <b-form-input
                          type="text"
                          v-decimal.unsigned
                          @keyup="product.length = twoDecimals(product.length)"
                          id="fdLength"
                          v-model="product.length"
                          :state="isValidCubic(product.length)"
                          required
                          :readonly="readonly"
                          :maxlength="7"
                        />
                      </div>
                      <div class="form-group col-md-1">
                        <label for="fdWidth">Width <span class="text-danger font-weight-bold">*</span></label>
                        <b-form-input
                          type="text"
                          v-model="product.width"
                          @keyup="product.width = twoDecimals(product.width)"
                          v-decimal.unsigned
                          :state="isValidCubic(product.width)"
                          required
                          id="fdWidth"
                          :readonly="readonly"
                          :maxlength="7"
                        />
                      </div>
                      <div class="form-group col-md-1">
                        <label for="fdHeight">Height <span class="text-danger font-weight-bold">*</span></label>
                        <b-form-input
                          type="text"
                          v-decimal.unsigned
                          :state="isValidCubic(product.height)"
                          @keyup="product.height = twoDecimals(product.height)"
                          required
                          id="fdHeight"
                          v-model="product.height"
                          :readonly="readonly"
                          :maxlength="7"
                        />
                      </div>
                      <div class="form-group col-md-1">
                        <label for="fdLinearUnits">Linear Feet</label>
                        <input tabindex="-1"
                          readonly
                          type="text"
                          class="form-control"
                          id="fdLinearUnits"
                          placeholder="Units"
                          v-model="product.linear_units"
                        />
                      </div>
                      <div class="form-group col-md-1">
                        <label for="fdCubicUnits">Cubic Feet</label>
                        <input tabindex="-1" 
                          readonly
                          type="text"
                          class="form-control"
                          id="fdCubicUnits"
                          placeholder="Units"
                          v-model="product.cubic_units"
                        />
                      </div>
                      <div class="form-group col-md-1">
                        <label for="fdDensityUnits">Density</label>
                        <input tabindex="-1" 
                          readonly
                          type="text"
                          class="form-control"
                          id="fdDensityUnits"
                          placeholder="Units"
                          v-model="product.density_units"
                        />
                      </div>
                      <div class="form-group form-check">
                        <label class="form-check-label" for="fdHazmat">Hazmat </label>
                        <div class="mt-2">
                          <input class="checkbox" :disabled="readonly" type="checkbox" id="fdHazmat" v-model="product.hazmat_toggle">
                        </div>
                      </div>
                    </div>
                </div>
                
                <div v-if="product.hazmat_toggle">
                  <div
                    class="mt-1 h5 text-primary"
                    :class="[
                      showHazardousMaterials ? null : 'collapsed',
                      showHazardousMaterials ? null : 'bg-warning',
                    ]"
                    :aria-expanded="showHazardousMaterials ? 'true' : 'false'"
                    aria-controls="collapse-3"
                  >
                    <i
                      @click="showHazardousMaterials = !showHazardousMaterials"
                      :class="showHazardousMaterials ? collapsedIcon : collapseIcon"
                      role="button"
                    ></i>
                    Hazardous Materials
                  </div>
                  <b-collapse
                    class="form-row bg-warning"
                    id="collapse-3"
                    v-model="showHazardousMaterials"
                  >
                    <div class="col m-2">
                      <HazardInfo 
                        v-bind:showValidations="showValidationsAcceptQuote"
                        @onChange="product.hazmat_details = $event.hazmatData"
                        :existingData="product.hazmat_details"
                        :readonly="readonly"/>
                    </div>
                  </b-collapse>
                </div>
              </div>
            </ul>
          </div>
        </b-collapse>

        <div
          class="mt-1 h5 text-primary"
          :class="showHandlingRequirements ? null : 'collapsed'"
          :aria-expanded="showHandlingRequirements ? 'true' : 'false'"
          aria-controls="collapse-4"
        >
          <i
            @click="showHandlingRequirements = !showHandlingRequirements"
            :class="showHandlingRequirements ? collapsedIcon : collapseIcon"
            role="button"
          ></i>
          Handling Requirements
        </div>
        <b-collapse
          class="form-row"
          id="collapse-4"
          v-model="showHandlingRequirements"
        >
          <div class="col-md-2 m-2 card">
            <div
              class="h6 text-center m-0 mt-2 rounded text-white  bg-primary"
              v-b-modal.service-modal-scrollable
            >
              <span class="d-flex justify-content-center mt-2 mb-2 ml-2">
                Select Services
              </span>
            </div>
            <div class="m-1">
              <div
                class="badge-container"
                :key="option.id"
                v-for="option in handlingRequirements"
              >
                <b-badge variant="light" class="badge-text">
                  <span
                    v-b-tooltip.hover
                    :title="option.title"
                    variant="success"
                    >{{ option.title }}
                  </span>
                </b-badge>
                <i v-if="!readonly"
                  class="fa fa-times in-badge"
                  @click="removeHandlingRequirement(option.id)"
                ></i>
              </div>
            </div>
          </div>
          <div class="col-md-4 m-2 card">
            <div
              class="h6 text-center m-0 mt-2 rounded text-white bg-primary"
              v-b-modal.insurance-modal-scrollable
            >
              <span class="d-flex justify-content-center mt-2 mb-2 ml-2">
                Select Insurance
              </span>
            </div>
            <div class="m-1">
              <div
                class="badge-container"
                :key="option.id"
                v-for="option in insuranceRequirements"
              >
                <b-badge variant="light" class="badge-text">
                  <span
                    v-b-tooltip.hover
                    :title="option.title"
                    variant="success"
                    >{{ option.title }}
                  </span>
                </b-badge>
                <span class="ins-amount" v-if="option.value"
                  >( {{ option.value }} )</span
                >
                <i v-if="!readonly"
                  class="fa fa-times in-badge"
                  @click="removeInsuranceRequirement(option.id)"
                  aria-hidden="true"
                ></i>
              </div>
            </div>
          </div>
          <div class="col-md-2 m-2 card">
            <div class="h6 text-center m-0 mt-2 rounded text-white bg-primary">
              <span class="d-flex justify-content-center mt-2 mb-2 ml-2">
                Freight
              </span>
            </div>
            <div class="m-1">
              <b-form-checkbox v-model="freightDetails.stackable" :disabled="readonly">
                Stackable
              </b-form-checkbox>
            </div>
          </div>
        </b-collapse>
        <div
          class="mt-1 h5 text-primary"
          :class="[showReferenceNumber ? null : 'collapsed']"
          :aria-expanded="showReferenceNumber ? 'true' : 'false'"
          aria-controls="collapse-5"
        >
          <i
            @click="showReferenceNumber = !showReferenceNumber"
            :class="showReferenceNumber ? collapsedIcon : collapseIcon"
            role="button"
          ></i>
          Reference Numbers - Notes
        </div>
        <b-collapse
          class="form-row"
          id="collapse-5"
          v-model="showReferenceNumber"
        >
          <div class="form-group col-md-4">
            <label for="rnShipperReference">Shipper Reference #</label>
            <input
              type="text"
              class="form-control"
              id="rnShipperReference"
              :maxlength="28"
              v-model="notes.shipper_reference_number"
              :readonly="readonly"                        
            />
          </div>
          <div class="form-group col-md-4">
            <label for="rnPONumber">PO #</label>
            <input
              type="text"
              class="form-control"
              id="rnPONumber"
              :maxlength="32"
              v-model="notes.po_number"
              :readonly="readonly"
            />
          </div>
          <div class="form-group col-md-4">
            <label for="rnConsigneeReference">Consignee Reference #</label>
            <input
              type="text"
              class="form-control"
              id="rnConsigneeReference"
              :maxlength="28"
              v-model="notes.consignee_reference_number"
              :readonly="readonly"
            />
          </div>
          <div class="form-group col-md-12">
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">BOL Notes</span>
              </div>
              <textarea
                class="form-control"
                aria-label="Notes to England Logistics"
                v-model="notes.bol_notes"
                :maxlength="8000"
                :readonly="readonly"
              ></textarea>
            </div>
          </div>
          <div class="form-group col-md-12">
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">Notes to England Logistics</span>
              </div>
              <textarea
                class="form-control"
                aria-label="Notes to England Logistics"
                v-model="notes.england_logistics_notes"
                :maxlength="80"
                :readonly="readonly"
              ></textarea>
            </div>
          </div>
        </b-collapse>
        <div class="form-group col-md-12">
          <b-button v-if="!readonly"
            class="mt-1"
            variant="primary"
            :disabled="isBusy"
            @click="submitOrder"
            >{{submitText}}
            <b-spinner v-if="isBusy" small aria-hidden="true"></b-spinner>
          </b-button>
        </div>
      </div>
    </div>
    </b-overlay>
    <b-modal v-if="!readonly"
      id="service-modal-scrollable"
      scrollable
      title="Select Service Items"
      hide-footer
    >
      <b-form-group label="Service Items">
        <b-form-checkbox
          v-for="option in handling_requirements_list"
          v-model="handlingRequirements"
          :key="option.id"
          :value="option"
          name="flavour-3a"
        >
          {{ option.title }}
        </b-form-checkbox>
      </b-form-group>
    </b-modal>
    <b-modal v-if="!readonly"
      id="insurance-modal-scrollable"
      scrollable
      title="Select Insurance Items"
      @ok="insModalOK"
    >
      <form ref="insSelectForm" @submit.stop.prevent="onInsItemSelect">
        <b-form-group label="Insurance Items">
          <b-container fluid>
            <b-row
              class="my-1 align-items-center"
              v-for="(option, index) in insurance_requirements_list"
              :key="option.id"
            >
              <b-col sm="8">
                <b-form-checkbox
                  :ref="`ins-checkbox-${option.id}`"
                  v-model="insuranceRequirements"
                  :key="option.id"
                  :value="option"
                  @change="
                    onInsuranceCheck(
                      `ins-checkbox-${option.id}`,
                      `type-${option.id}`,
                      index
                    )
                  "
                  :name="`flavour-3a-${option.id}`"
                >
                  {{ option.title }}
                </b-form-checkbox>
              </b-col>
              <b-col sm="4">
                <b-form-input
                  :ref="`type-${option.id}`"
                  @focus.native="$event.target.select()"
                  :id="`type-${option.id}`"
                  v-model="option.value"
                  :state="setValidationState(option.id)"
                  :required="setRequiredIfChecked(option.id)"
                  type="text"
                  v-integer.unsigned
                ></b-form-input>
              </b-col>
            </b-row>
          </b-container>
        </b-form-group>
      </form>
    </b-modal>
  </div>
</template>
<script>
import FormLocation from "@/components/forms/FormLocation";
import ViewIdentity from "@/components/ViewIdentity";
import HazardInfo from "@/components/HazardInfo";
import ProductsService from "@/common/products.service";
import OrderService from "@/common/orders.service";
import QuoteService from "@/common/quotes.service";
import CustomSelect from "@/components/CustomSelect";

export default {
  components: {
    FormLocation,
    ViewIdentity,
    HazardInfo,
    CustomSelect,
  },
  props: {
    readonly: Boolean,
    orderData: {}
  },

mounted() {
    ProductsService.getProducts('').then(
      (products) => {
        this.locationProducts = products;
      }
    );
    this.save_product_types = [
            {
                field_code_desc : 'Save to Shipping Location',
                field_code: '1'
            },
            {
                field_code_desc : 'Save to Customer Profile',
                field_code: '2'
            }
        ];
    var orderId = this.$route.query.orderId;  
    var selectedRate = this.$route.params.selectedRate;
    var showQuoteValidations = false;
    if(selectedRate){
      this.selectedRate = selectedRate;
      this.selectedRateOriginal = selectedRate;
      this.submitText = 'Confirm Booking';
      showQuoteValidations = true;
    }
    
    
    OrderService.getHandlingRequriments().then((data) => {
      this.handling_requirements_list = data;
    });

    OrderService.getInsuranceRequriments().then((data) => {
      this.insurance_requirements_list = data;
    });

    if(this.orderData){
      this.loadOrder(this.orderData);
    }
    else
    {
      if (orderId) {
        this.isBusy = true;
        OrderService.getOrderByID(orderId)
          .then((data) => {
            this.loadOrder(data);
            if(showQuoteValidations){
              this.showValidationsAcceptQuoteOriginal = true;
              this.showValidationsAcceptQuote = true;
              setTimeout(() => {
                this.validate();
              }, 100)
            }
          })
          .catch((err) => {
            console.log(err);
            this.notifyError("There was an error retrieving the quote");
          })
          .finally(() => {
            this.isBusy = false;
          });
      }
      else
      {
        var startDate = new Date();
        this.shippingSchedule.pickup_date = [startDate.getFullYear(),('0' + (startDate.getMonth() + 1)).slice(-2),('0' + startDate.getDate()).slice(-2)].join('-');
        this.shippingSchedule.pickup_early = "06:00";
        this.shippingSchedule.pickup_late = "16:00";
        setTimeout(() => {
          this.dataLoaded = true;
        }, 100)
      }
    }
    
    if (this.productPackagings.length === 0) {
      ProductsService.getProductPackagings().then((data) => {
        this.productPackagings = data;
      });
    }
    
    if (this.productClases.length === 0) {
      ProductsService.getProductClases().then((data) => {
        this.productClases = data;
      });
    }
  },
  data() {
    return {
      submitting: false,
      dataLoaded: false,
      recalculate: false,
      submitText: 'Get Rates',
      orderId: null,
      isUpdate: false,
      showValidations: false,
      showValidationsAcceptQuote: false,
      save_product_types: [],
      handlingRequirements: [], // Must be an array reference!
      handling_requirements_list: [],
      insuranceRequirements: [], // Must be an array reference!
      insurance_requirements_list: [],
      showAddress: false,
      showShipSchedule: true,
      showFreightSummary: true,
      showHazardousMaterials: true,
      showHandlingRequirements: false,
      showReferenceNumber: false,
      collapseIcon: "fa fa-plus fa-xs",
      collapsedIcon: "fa fa-minus fa-xs",

      isBusy: false,
      productPackagings: [],
      productClases: [],

      locationProducts: [],
      locationProductsId: null,
      selectedProduct: "",
      saveProduct: false,

      // quote form fields
      origin: null,
      destination: null,
      logistics_notes: "",
      bol_notes: "",
      consignee_number: "",

      shipping_number: "",
      nmfc_max: 20,
      nmfc_text: "",
      hazardousMaterials: {
        unit_number: "",
        hazmat_class: [],
        sub_class: "",
        package_group: [],
        erg_number: [],
        poison: false,
        poison_zone: false,
        emergency_phone: 0,
        contact: "",
        shipping_name: "",
      },
      services: {
        liftgate_service: false,
        another_service: false,
      },
      insurance: {
        computers_price: "",
        electronics_price: "",
        others_price: "",
      },
      freightDetails: {
        // this is a array of the freight detail line items
        products: [{ ...OrderService.EmptyProduct() }],
        stackable: false,
      },
      shippingSchedule: {
        pickup_late: null,
        pickup_early: null,
        pickup_date: new Date().toLocaleDateString(),
      },
      notes: {
        shipper_reference_number: "",
        po_number: "",
        consignee_reference_number: "",
        bol_notes: "",
        england_logistics_notes: "",
      },

      invalid: {
        description: [],
        packaging: [],
        pieces: [],
        weightProduct: [],
        class: [],
        spots: [],
        handling_units: [],
        units: [],
        length: [],
        width: [],
        height: [],
      },
    };
  },
  computed: {
    toggleState(value) {
      return value !== "";
    },
    pickup_early_state() {
      return this.shippingSchedule.pickup_early !== "";
    },
    pickup_late_state() {
      return this.shippingSchedule.pickup_late !== "";
    },
  },
  methods: {
    newChanges(){
      if(!this.submitting)
      {
        this.submitText= 'Get Rates';
        this.selectedRate = '';
        this.recalculate = true;
        this.showValidationsAcceptQuote = false;
      }
    },
    changesReverted(){
      if(this.selectedRateOriginal){
        this.submitText= 'Confirm Booking';
        this.selectedRate = this.selectedRateOriginal;
        this.recalculate = false;
        this.showValidationsAcceptQuote = this.showValidationsAcceptQuoteOriginal;
      }
      else{
        this.newChanges();
      }
    },
    total_density() {
      var total = 0;
      this.freightDetails.products.forEach((item) => {
        total += parseFloat(item.density_units);
      });
      return total;
    },
    total_cubic() {
      var total = 0;
      this.freightDetails.products.forEach((item) => {
        total += item.cubic_units;
      });
      return total;
    },
    total_linear() {
      return this.shippingSchedule.pickup_late !== "";
    },
    loadOrder(data){
      
      this.orderId = data.order.id;
      this.isUpdate = true;
      this.freightDetails = data.order.freight_details;
      //temporary fix
      if(data.order.origin.contact_name){
        data.order.origin.location.contact_name = data.order.origin.contact_name;
        data.order.origin.location.contact_phone = data.order.origin.contact_phone;
        data.order.destination.location.stop_id = data.order.destination.stop_id;
        data.order.origin = data.order.origin.location;
      }
      if(data.order.destination.contact_name){
        data.order.destination.location.contact_name = data.order.destination.contact_name;
        data.order.destination.location.contact_phone = data.order.destination.contact_phone;
        data.order.destination.location.stop_id = data.order.destination.stop_id;
        data.order.destination = data.order.destination.location;
      }
      //end temporary fix
      this.$refs.locationOrigin.setLocation(data.order.origin);
      this.$refs.locationDestination.setLocation(data.order.destination);
      
      this.handlingRequirements = data.order.handling_requirements;
      this.insuranceRequirements = data.order.insurance_requirements;
      if (data.order.shipping_schedule) {
        var startDate = new Date(data.order.shipping_schedule.pickup_early);
        this.shippingSchedule.pickup_date = [startDate.getFullYear(),('0' + (startDate.getMonth() + 1)).slice(-2),('0' + startDate.getDate()).slice(-2)].join('-');
        this.shippingSchedule.pickup_early = new Date(data.order.shipping_schedule.pickup_early.replace('Z','').substring(0,16)).toLocaleTimeString('en-GB', { hour12: false });
        this.shippingSchedule.pickup_late = new Date(data.order.shipping_schedule.pickup_late.replace('Z','').substring(0,16)).toLocaleTimeString('en-GB', { hour12: false })
        this.shippingSchedule.pickup_early = this.shippingSchedule.pickup_early.replace(':00','');
        this.shippingSchedule.pickup_late = this.shippingSchedule.pickup_late.replace(':00','');
      }

      this.insurance_requirements_list.forEach((item) => {
        this.insuranceRequirements.forEach((item2) => {
          if(item.id === item2.id)
          {
            item.value = item2.value;
          }
        });
      });

      this.notes = data.order.notes;
      this.freightDetails.products.forEach((product) => {
        //temporary fix
        if(product.class){
          product.nmfc_class_code = product.class;
        }
        if(product.spots){
          product.req_spots = product.spots;
        }
        if(product.nmfc){
          product.nmfc_code = product.nmfc;
        }
        if(product.weight.amount){
          product.weight = product.weight.amount;
        }
        //end temporary fix
        this.calculatedFields(product);
      });
      
      setTimeout(() => {
        this.dataLoaded = true;
      }, 100)
    },
    isValid(value) {
      if (this.showValidations && !value) {
        return false;
      }
      return null;
    },
    isValidNumber(value) {
      if (this.showValidations && (!value || value <= 0)) {
        return false;
      }
      return null;
    },
    isValidToAcceptQuote(value){
      if (this.showValidationsAcceptQuote && !value) {
        return false;
      }
      return null;
    },

    isValidDescription(product){
      if (this.showValidationsAcceptQuote && !product.description) {
        return false;
      }
      if (this.showValidationsAcceptQuote && product.description.trim() === '') {
        return false;
      }
      if (this.showValidations && product.save_product &&!product.description) {
        return false;
      }
      return null;
    },
    isValidCubic(value) {
      if (this.showValidations && (!value || value <= 0)) {
        return false;
      }
      return null;
    },
    // amount input field run time required on the basis of wheather the insurance item is checked or not
    setRequiredIfChecked(id) {
      let isItemInSelected = this.insuranceRequirements.filter(
        (ins) => ins.id === id
      );
      if (isItemInSelected && isItemInSelected.length) return true;
      else return false;
    },

    // focus to amount input if user check the insurance item
    // on uncheck removing the amount filled
    onInsuranceCheck(checkboxRef, amountInputRef, index) {
      if (this.$refs[checkboxRef][0].isChecked) {
        this.$refs[amountInputRef][0].focus();
      } else {
        this.insurance_requirements_list[index].value = null;
      }
    },

    // to change amount input state when value set to null, empty string, or zero
    setValidationState(id) {
      let isItemInSelected = this.insuranceRequirements.filter(
        (ins) => ins.id === id
      );
      if (isItemInSelected && isItemInSelected.length) {
        let item = isItemInSelected[0];
        if (!item.value || item.value == 0 || item.value == "") return false;
        else return true;
      }
    },

    // check if the insurance modal form is valid or not
    checkInsModalFormValidity() {
      const valid = this.$refs.insSelectForm.checkValidity();
      return valid;
    },

    // run when insurance modal OK button will clicked
    insModalOK(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.onInsItemSelect();
    },

    onInsItemSelect() {
      // Exit when the form isn't valid
      if (!this.checkInsModalFormValidity()) {
        return;
      }
      // Hide the modal manually
      this.$nextTick(() => {
        this.$bvModal.hide("insurance-modal-scrollable");
      });
    },

    calculatedFields(product) {
      if (product) {
        if(product.length){
          product.linear_units = Math.round(product.length / 12 * product.handling_units);
        }
        if(product.length && product.width && product.height){
          product.cubic_units = Math.round(((product.length * product.width * product.height) / 1728) * product.handling_units);
        }
        if (product.cubic_units && product.cubic_units !== 0) {
          product.density_units = parseFloat(
            product.weight / product.cubic_units,
            4
          ).toFixed(4);
        }
      }
    },

    removeHandlingRequirement(id) {
      this.handlingRequirements = this.handlingRequirements.filter(
        (item) => item.id !== id
      );
    },

    removeInsuranceRequirement(id) {
      this.insurance_requirements_list.find(
        (ins) => ins.id === id
      ).value = null;
      this.insuranceRequirements = this.insuranceRequirements.filter(
        (item) => item.id !== id
      );
    },
    loadProductDetails: function(index, item) {
        this.calculatedFields(item);
        this.$set(this.freightDetails.products, index, JSON.parse(JSON.stringify(item)));

        // if hazmat details are included in this product then render hazmat.
        if (this.freightDetails.products[index].hazmat_toggle) {
          this.showHazardousMaterials = true;
        }
    },
    addProduct: function() {
      var newProduct = OrderService.EmptyProduct();
      this.freightDetails.products.push( newProduct );
    },
    removeProduct: function(index) {
      this.freightDetails.products.splice(index, 1);
    },
    validate: function() {
      this.showValidations = true;
      var validForm = true;
      var today = new Date();
      var now = new Date();
      var pickupDate = new Date(this.shippingSchedule.pickup_date + ' 00:00');
      
      today.setHours(0,0,0,0);
      pickupDate.setHours(0,0,0,0);
      if(today > pickupDate){
        this.notifyError("Past shipping schedule, please fix.")
        return false
      }

      var earlyDate = new Date(pickupDate.toLocaleDateString() +" " +this.shippingSchedule.pickup_early);
      var endDate = new Date(pickupDate.toLocaleDateString() +" " +this.shippingSchedule.pickup_late);
      var scheduleDiff = parseInt(Math.abs(earlyDate.getTime() - endDate.getTime()) / 60000 );
      var scheduleDiff2 = parseInt((endDate - now) / 60000 );

      if(!this.validateToAcceptQuote()){
        this.notifyError("Please complete the highlighted fields in order to accept a rate quote")
        return false
      }

      if (!this.origin?.location?.city ||
          !this.origin?.location?.state ||
          !this.origin?.location?.zip)
      {
        this.notifyError("Origin can not be empty");
        validForm = false;
      } else if (
          !this.destination?.location?.city ||
          !this.destination?.location?.state ||
          !this.destination?.location?.zip)
      {
        this.notifyError("Destination can not be empty");
        validForm = false;
      } 
      else if (this.origin.cities.length === 0 && !this.origin.location.id ){
        this.notifyError("Invalid origin city");
        validForm = false;
      }
      else if (this.destination.cities.length === 0  && !this.destination.location.id ){
        this.notifyError("Invalid destination city");
        validForm = false;
      }      
      else if (scheduleDiff < 60 || earlyDate.getTime() > endDate.getTime()){
        this.notifyError("Shipping schedule min difference have to be 60 minutes");
        validForm = false;
      }
      else if (scheduleDiff2 < 60){
        this.notifyError("Late Pickup Date needs to be at least 60 minutes from the current time");
        validForm = false;
      }
      else if (earlyDate.getHours() * 60 + earlyDate.getMinutes() > 1140 ||
          endDate.getHours() * 60 + endDate.getMinutes() > 1140){
        this.notifyError("Shipping schedule pick ups can't be after 7:00 pm");
        validForm = false;
      }
      if(this.origin?.saveLocation){
        if(!this.origin?.location?.name
          || !this.origin?.location?.address_1 
          || !this.origin?.contact_name
          || !this.origin?.contact_phone 
          || this.origin?.contact_name.trim() === ''
          || this.origin?.location?.address_1.trim() === ''
          )
        {
          this.notifyError("Fields are required to save the origin");
          validForm = false;
        }
      }
      if(this.destination?.saveLocation){
        if(!this.destination?.location?.name
          || !this.destination?.location?.address_1
          || !this.destination?.contact_name
          || !this.destination?.contact_phone
          || this.destination?.contact_name.trim() === ''
          || this.destination?.location?.address_1.trim() === ''
          )
        {
          this.notifyError("Fields are required to save the destination");
          validForm = false;
        }
      }
      this.freightDetails.products.forEach(product => {
        if(product.save_product){
          var filteredItems = this.locationProducts.filter(f=> {
            if(f['description'] && product.description)
              return f['description'].toLowerCase().indexOf(product.description.toLowerCase()) > -1;
          });
          if(!product.description) {
            this.notifyError('Product description is required when adding product to the list.');
            validForm = false;
          }
          else if((!this.origin.location.location_id || !this.origin.location.location_id) && product.save_product_type !== 'Save to Customer Profile') {
            this.notifyError('An existing origin location is required when adding product to the list.');
            validForm = false;
          }
          else if(filteredItems && filteredItems.length > 0) {
            this.notifyError('A product with the same description is already existing');
            validForm = false;
          }
        }
        if (!product.packaging ||
            !product.pieces ||
            product.pieces <= 0 ||
            !product.nmfc_class_code ||
            !product.req_spots ||
            product.req_spots <= 0 ||
            !product.handling_units ||
            product.handling_units <= 0 ||
            !product.weight ||
            product.weight <= 0 ||
            !product.length ||
            product.length <= 0 ||
            !product.width ||
            product.width <= 0 ||
            !product.height ||
            product.height <= 0 ||
            !product.linear_units ||
            !product.cubic_units ||
            !product.density_units) {
          this.notifyError(`In freight details, necessary fields must be filled`);
          validForm = false;
        }
      });

      return validForm;
    },
    validateToAcceptQuote(){
      var isValid = true;
      if(this.showValidationsAcceptQuote){
        this.freightDetails.products.forEach((item) => {
          if (!item.description
              || item.description.trim() === ''){
            isValid = false;
          }
          if(item.hazmat_toggle) {
            if (!item.hazmat_details.contact ||
                !item.hazmat_details.emergency_phone ||
                !item.hazmat_details.hazmatClass ||
                !item.hazmat_details.hazmatUnnaNbr ||
                !item.hazmat_details.hazmatContactType ||
                !item.hazmat_details.shipping_name){
              this.notifyError('In freight details hazardous materials, necessary fields must be filled');
              isValid = false;
            }
          }
        });
        if(!this.origin?.location?.name
          || !this.origin?.location?.address_1 
          || !this.origin?.contact_name
          || !this.origin?.contact_phone 
          || !this.destination?.location?.name
          || !this.destination?.location?.address_1
          || !this.destination?.contact_name
          || !this.destination?.contact_phone
          || this.origin?.location?.name.trim() === ''
          || this.origin?.location?.address_1.trim() === ''
          || this.origin?.contact_name.trim() === ''
          || this.destination?.location?.name.trim() === ''
          || this.destination?.location?.address_1.trim() === ''
          || this.destination?.contact_name.trim() === ''
          )
        {
            this.$refs['showAddressButton'].click();
            isValid = false;
        }
      }
      return isValid;
    },
    twoDecimals(value){
      if(value){
        value = value.match(/^\d+\.?\d{0,2}/);
        return value[0];
      }
      return undefined;
      
    },
    saveDefaults: function(comp, name) {
      if (comp.setDefault) {
        let user = JSON.parse(localStorage.getItem("user"));
        var save = comp.saveLocation;
        comp.saveLocation = false;
        comp.setDefault = false;
        let data = JSON.stringify(comp);
        localStorage.setItem("defaultLocation" + name + user.email_address, data);
        comp.saveLocation = save;
        comp.setDefault = true;
      }
    },
    submitOrder: function() {
      this.submitting = true;
      this.isBusy = true;
      if (this.validate()) {
        this.shippingSchedule.pickup_date = new Date(
          this.shippingSchedule.pickup_date + ' 00:00'
        );
        var schedule = {
          pickup_date: this.shippingSchedule.pickup_date,
          pickup_early: new Date(
            this.shippingSchedule.pickup_date.toLocaleDateString() +
              " " +
              this.shippingSchedule.pickup_early
          ).toLocaleString(),
          pickup_late: new Date(
            this.shippingSchedule.pickup_date.toLocaleDateString() +
              " " +
              this.shippingSchedule.pickup_late
          ).toLocaleString(),
        };
        this.saveDefaults(this.origin,'Origin');
        this.saveDefaults(this.destination,'Destination');
        this.origin.cities = [{}];
        this.destination.cities = [{}];
        var payload = {
            order: {
              origin: this.origin,
              destination: this.destination,
              shipping_schedule: schedule,
              freight_details: this.freightDetails,
              notes: this.notes,
              handling_requirements: this.handlingRequirements,
              insurance_requirements: this.insuranceRequirements,
              id: this.orderId,
              recalculate: this.recalculate,
            },
            create_product: this.saveProduct
          };
        OrderService.saveOrder(payload, this.isUpdate)
          .then((order) => {
            // order creation was successful route to quote page and pass the order ID
            console.log(order);
            if (this.selectedRate) {
              QuoteService.acceptCarrierRate(this.selectedRate)
                .then(() => {
                  this.$router.push({ name: 'order', query: { orderId: this.orderId } })
                })
                .catch(() => {
                  this.notifyError("There was an error selecting the rate")
                  this.submitting = false;
                });
            }
            else{
              this.$router.push({
                path: "summary",
                query: { orderId: order.id },
              });
            }
          })
          .catch((err) => {
            console.log(err);
            this.notifyError("Error saving order");
            this.isBusy = false;
          })
          ;
      } else {
        this.isBusy = false;
        this.submitting = false;
      }
    },
  },
  watch: {
    freightDetails:{
      handler: function(details) {
        var newDetails = JSON.stringify(details, ["products","length", "nmfc_class_code", "weight", "height", "req_spots", "pieces", "width", "packaging", "handling_units"]);
        if(this.dataLoaded){
          if(!this.freightDetailsJson){
            this.freightDetailsJson = newDetails;
          }
          if(newDetails.replaceAll('"','') !== this.freightDetailsJson.replaceAll('"','')){
            this.newChanges();
          }
          else{
            this.changesReverted();
          }
        }
        details.products.forEach((item) => {
          this.calculatedFields(item);
        });
        if(!this.dataLoaded){
          this.freightDetailsJson = newDetails;
        }
      },
      deep: true
    },
    destination: {
      handler: function(newDestination) {
        var newDestinationJson = JSON.stringify(newDestination.location, ["city","state","zip"]);
        if(this.dataLoaded){
          if(!this.destinationJson){
            this.destinationJson = newDestinationJson;
          }
          if(newDestinationJson.replaceAll('"','') !== this.destinationJson.replaceAll('"','')){
            this.newChanges();
          }
          else{
            this.changesReverted();
          }
        }
        if(!this.dataLoaded){
          this.destinationJson = newDestinationJson;
        }
      },
      deep: true,
    },
    origin: {
      handler: function(newOrigin) {
        var newOriginJson = JSON.stringify(newOrigin.location, ["city","state","zip"]);
        if(this.dataLoaded){
          if(!this.originJson){
            this.originJson = newOriginJson;
          }

          if(newOriginJson.replaceAll('"','') !== this.originJson.replaceAll('"','')){
            this.newChanges();
          }
          else{
            this.changesReverted();
          }
        }
        // don't try to grab the products again if the ID didn't change
        if (newOrigin.location.location_id === this.locationProductsId) {
          return;
        }

        this.locationProductsId = newOrigin.location.location_id;

        // only load products if they've selected an origin location
        if (newOrigin.location && newOrigin.location.location_id) {
          ProductsService.getProducts(newOrigin.location.location_id).then(
            (products) => {
              this.locationProducts = products;
            }
          );
        }
        else{
          ProductsService.getProducts('').then(
            (products) => {
              this.locationProducts = products;
            }
          );
        }
        if(!this.dataLoaded){
          this.originJson = newOriginJson;
        }
      },
      deep: true,
    },
    handlingRequirements: {
      handler: function(newSelected) {
        newSelected.forEach((item) => {
          if (item.value) item.value = item.value.replace(/^0+/, "");
        });

        var newHandlingRequirementsJson = JSON.stringify(newSelected);        
        if(this.dataLoaded){
          
          if(!this.handlingRequirementsJson){
            this.handlingRequirementsJson = newHandlingRequirementsJson;
          }
          if(newHandlingRequirementsJson.replaceAll('"','') !== this.handlingRequirementsJson.replaceAll('"','')){
            this.newChanges();
          }
          else{
            this.changesReverted();
          }
        }
        else{
          this.handlingRequirementsJson = newHandlingRequirementsJson;
        }
      },
      deep: true,
    },
    insuranceRequirements: {
      handler: function(newSelected) {
        newSelected.forEach((item) => {
          if (item.value) item.value = item.value.replace(/^0+/, "");
        });

        
        var newInsuranceRequirementsJson = JSON.stringify(newSelected);        
        if(this.dataLoaded){
          if(!this.insuranceRequirementsJson){
            this.insuranceRequirementsJson = newInsuranceRequirementsJson;
          }
          if(newInsuranceRequirementsJson.replaceAll('"','') !== this.insuranceRequirementsJson.replaceAll('"','')){
            this.newChanges();
          }
          else{
            this.changesReverted();
          }
        }
        else{
          this.insuranceRequirementsJson = newInsuranceRequirementsJson;
        }
        
      },
      deep: true,
    },
    shippingSchedule: {
      handler: function(newSelected) {
        var newShippingScheduleJson  = JSON.stringify(newSelected);  
        if(this.dataLoaded){
          if(!this.shippingScheduleJson){
            this.shippingScheduleJson = newShippingScheduleJson;
          }
          if(newShippingScheduleJson.replaceAll('"','') !== this.shippingScheduleJson.replaceAll('"','')){
            this.newChanges();
          }
          else{
            this.changesReverted();
          }
        }
        else{
          this.shippingScheduleJson = newShippingScheduleJson;
        }
        
      },
      deep: true,
    },
    
  },
};
</script>
<style scoped>
input {
  margin: 2px;
}

.badge-container {
  display: inline-flex;
  padding: 0;
  margin: 0;
  vertical-align: middle;
  align-items: center;
  max-width: 100%;
  margin-right: 5px;
}

.badge-text {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
i.in-badge {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  background: #f0f0f0;
  padding: 3px 6px 3px 3px;
  cursor: pointer;
}

.ins-amount {
  background: #f0f0f0;
  padding: 0.15rem 0 0.15rem 0;
  font-size: 75%;
}

i.in-badge::before {
  padding-top: 2px;
  opacity: 0.7;
}

.badge.badge-light {
  flex: 1;
  font-weight: 400;
  padding: 5px 2px 5px 8px;
  background: #f0f0f0;
}
.collapsed > .when-opened,
:not(.collapsed) > .when-closed {
  display: none;
}
.checkbox{
  min-width: 16px;
  min-height: 16px;
}
</style>
