<template>
  <div class="login-bg">
      <div class="inner">
        <FormSignUp/>
      </div>
  </div>
</template>
<script>
import FormSignUp from "@/components/forms/FormSignUp";

export default {
  components: {FormSignUp},
}
</script>
<style>
.login-bg {
  background-color: rgb(240, 240, 240);
  background-image: url("../assets/background.png");
  background-repeat: no-repeat;
  background-size: 100vw 100vh ;
  background-position: center;
  height: 100vh;
  width: 100vw;
}

.inner {
  display: table;
  margin: 0 auto;
}
</style>