import axios from "axios";
let cancelToken;

const OrderService = {
  saveOrder(order, isUpdate) {
    var url = '/Order';
    if(isUpdate){
      url='/Order/UpdateOrder'
    }
    return axios.post(url, order)
    .then((res) => {
      return res.data;
    }).catch( error => {
      console.log(error);
    }          
    );
  },

  getTracking(orderId) {
    return axios.get(`/Order/GetTrackingInfoByOrder?orderId=${orderId}`)
    .then(res => {
        return res.data;
    });
  },
  getInvoices() {
    return axios.get(`/Order/GetInvoiceAmounts`)
    .then(res => {
      return res.data;
    });
  },
  GetFreightSummary(orderId) {
      return axios.get("/Product/GetFreightSummary?orderId=" + orderId)
      .then(res => {
          return res.data;
      })
  },

  getOrderByID(orderId) {
    return axios.get(`/Order/GetOrderById?orderId=${orderId}`)
    .then((res) => {
      return res.data;
    });
  },

  getFilterOrders(filters, status, startDate, endDate) {
    //Check if there are any previous pending requests
    if (typeof cancelToken != typeof undefined) {
      cancelToken.cancel("Operation canceled due to new request.")
    }
    //Save the cancel token for the current request
    cancelToken = axios.CancelToken.source()

    const filterToSend = { ...filters };
    // convert filters to a comma delimited array of column=value
    filterToSend.OnlyBookedOrders = true;

    if (startDate) {
      let d = new Date();
      d.setTime(Date.parse(startDate))
      filterToSend.BeginDate = d
    }

    if (endDate) {
      let d = new Date();
      d.setTime(Date.parse(endDate))
      filterToSend.EndDate = d
    }
    filterToSend.status = status;
    return axios
    .post(`/Order/GetFilteredOrders`,JSON.parse(JSON.stringify(filterToSend)),
    {
      cancelToken: cancelToken.token
    })
    .then((res) => {
      return res.data;
    })
    .catch(function(err) {
        console.log('Previous request canceled, new request is send', err.message);
    });


  },

  getOrderCounts(start, end) {
    return axios.get(`/Order/GetStatusCounts?start=${start}&end=${end}`)
      .then((res) => {
        return res.data;
      });
  },
  getFedChildren() {
    return axios.get(`/Order/GetFedChildren`)
      .then((res) => {
        return res.data;
      });
  },

  sortAlphatically(arrayToSort, key) {
    if(arrayToSort){
      return arrayToSort.sort(function(a, b) {
        if (a[key] < b[key]) return -1;
        if (a[key] > b[key]) return 1;
        return 0;
      });
    }
  },

  getInsuranceRequriments() {
    return axios.get("/Requirements/getInsuranceRequirements").then((res) => {
      return this.sortAlphatically(res.data, "id");
    });
  },

  getHandlingRequriments() {
    return axios.get("/Requirements/getHandlingRequirements").then((res) => {
      return this.sortAlphatically(res.data, "title");
    });
  },

  EmptyProduct(){
    var result = new Object();
    result = {
      description: "",
      packaging: "",
      weight: '',
      weight_per_piece: false,
      class: "",
      nmfc: "",
      stackable: false,
      spots: "",
      spots_per_peice: false,
      handling_units: "",
      handling_units_per_piece: false,
      cube: 0,
      cubic_units_per_piece: false,
      hazmat_toggle: false,
      save_product: false,
      save_product_type: 'Save to Customer Profile',
      hazmat_details: {
        contact: "",
        emergency_phone: "",
        poison: false,
        hazmatErgNumber: "",
        hazmatClass: "",
        sub_class: "",
        hazmatPacking: "",
        hazmatPoisonZone: "",
        hazmatUnnaNbr: "",
        hazmatContactType: "",
        hazmatContactTypeDesc: "Person",
        shipping_name: "",
      },
      sku: "",
      value: 0,
      value_per_piece: false,
      length: "",
      width: "",
      height: "",
      temperature: {
        min: 0,
        max: 0,
        unit: "TEMPERATURE_UNIT_INVALID",
      },
      linear_units: 0,
      cubic_units: 0,
      density_units: 0,
      product_id: "",
      fgi_uid: null
    };
    return result;
  },

  getMapData(type){
    return axios.get(`/Location/getMapInfo?type=${type}`
    ).then((res) => {
      return res.data;
    });
  }
};

export default OrderService;
