import axios from 'axios';
let cancelToken;

const CityService = {

    getCities(city, state, zip) {
        //Check if there are any previous pending requests
        if (typeof cancelToken != typeof undefined) {
          cancelToken.cancel("Operation canceled due to new request.")
        }
        //Save the cancel token for the current request
        cancelToken = axios.CancelToken.source();
        
        return axios
        .get(`/Location/GetCities?city=${city}&state=${state}&zip=${zip}`,{cancelToken: cancelToken.token})
        .then(res => {
            return res.data;
        });
    },
}

export default CityService;