import axios from "axios";

const DocumentService = {
    getOptions(orderId) {
        return axios.get(`http://tms-log-qatestnew:8080/v1/orders/${orderId}/documents:options`)
            .then(res => {
                return res.data.options;
            });
    }
};

export default DocumentService;