<template>
  <div class="card p-1">
    <div class="ml-3">
      <div class="w-100 row p-1">
        <div class="col m-1">
          <label>Start Date 
            <b-spinner v-if="isBusy"
              variant="primary"
              label="Spinning"
            ></b-spinner>
          </label>
          <b-form-datepicker
              id="start_Date"
              size="sm"
              :max="end"
              v-model="start"
              :date-format-options="{
              year: 'numeric',
              month: 'short',
              day: '2-digit',
              weekday: 'short',
            }"
          ></b-form-datepicker>
        </div>
        <div class="col m-1">
          <label>End Date</label>
          <b-form-datepicker
              id="end_Date"
              size="sm"
              :min="start"
              v-model="end"
              :date-format-options="{
              year: 'numeric',
              month: 'short',
              day: '2-digit',
              weekday: 'short',
            }"
          ></b-form-datepicker>
        </div>
      </div>
      <div class="row pr-3 mx-auto">
        <b-button class="row p-2 m-1 w-100 mh-25 bg-primary" @click="$router.push({ name: 'booked', query: { status: 'Pending Assignment', start: start, end: end } }); ">
          Pending Assignment : {{ pending }} 
        </b-button>
        <b-button class="row p-2 m-1 w-100 mh-25 bg-primary" @click="$router.push({ name: 'booked', query: { status: 'To Be Picked Up', start: start, end: end } })">
          To Be Picked Up : {{ tobePickedUp }}
        </b-button>
        <b-button class="row p-2 m-1 w-100 mh-25 bg-primary" @click="$router.push({ name: 'booked', query: { status: 'In Transit', start: start, end: end } })">
          In Transit : {{ ofd }}
        </b-button>
        <b-button class="row p-2 m-1 w-100 mh-25 bg-primary" @click="$router.push({ name: 'booked', query: { status: 'Delivered', start: start, end: end } })">
          Delivered : {{ delivered }}
        </b-button>
        <b-button class="row p-2 m-1 w-100 mh-25 bg-primary" @click="$router.push({ name: 'review', query: { start: start, end: end } })">
          Quotes : {{ quotes }}
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import OrderService from "@/common/orders.service";

export default {
  data: function () {
    return {
      isBusy: false,
      pending: 0,
      tobePickedUp: 0,
      ofd: 0,
      delivered: 0,
      quotes: 0,
      start: '',
      end: '',
    };
  },
  methods: {
    getStatusCounts() {
      this.isBusy = true;
      OrderService.getOrderCounts(
          this.start,
          this.end
      ).then((statuses) => {
        this.tobePickedUp = statuses.tobePickedUp;
        this.pending = statuses.pendingAssignment;
        this.ofd = statuses.inTransit;
        this.delivered = statuses.delivered;
        this.quotes = statuses.quote;
      })
      .finally(()=> this.isBusy = false );
    },
  },
  watch: {
    start() {
      this.getStatusCounts();
    },
    end() {
      this.getStatusCounts();
    },
  },
  mounted() {
    
    var startDate = new Date();
    var endDate = new Date();
    //Set up the started date to 90 days in the past, to show the this range of days in the total
    startDate.setDate((startDate.getDate() - 90)); 

    this.start = [startDate.getFullYear(),('0' + (startDate.getMonth() + 1)).slice(-2),('0' + startDate.getDate()).slice(-2)].join('-');
    this.end = [endDate.getFullYear(),('0' + (endDate.getMonth() + 1)).slice(-2),('0' + endDate.getDate()).slice(-2)].join('-');
    this.getStatusCounts();
  },
};
</script>

<style></style>
