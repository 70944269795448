<template>
<div class="container-fluid">
  <form>
  <h5>Order Id: {{orderId}}, from: {{originZip}} to: {{destinationZip}}</h5>
  <div class="accordion" role="tablist">
    
    <div v-for="(item,index) in freightDetails" v-bind:key="item.id">
      <b-card no-body class="mb-1">
      <b-card-header header-tag="header" class="p-1" role="tab">
        <b-button class="text-left" block v-b-toggle="'collapse-'+index" variant="light">{{item.itemHeader}}</b-button>       
      </b-card-header>
        <b-collapse :id="'collapse-'+index" collapse ="my-accordion" role="tabpanel">
          <b-card-body>
      <div class="col m-2">
        <div class="form-row">
          <div class="form-group col-md-1">
            <label for="commodity">Commodity</label>
            <input type="text" class="form-control" id="commodity" readonly :value="item.id">
          </div>
          <div class="form-group col-md-1">
            <label for="description">Description</label>
            <input type="text" class="form-control" id="description" readonly :value="item.description">
          </div>
          <div class="form-group col-md-1">
            <label for="packaging">Packaging</label>
            <input type="text" class="form-control" id="packaging"  readonly :value="item.packaging">
          </div>
          <div class="form-group col-md-1">
            <label for="pieces">Pieces</label>
            <input type="text" class="form-control" id="pieces" readonly :value="item.pieces">
          </div>
          <div class="form-group col-md-1">
            <label for="weight">Weight</label>
            <input type="text" class="form-control" id="weight" readonly :value="item.weight">
          </div>
          <div class="form-group col-md-1">
            <label for="class">Class</label>
            <input type="text" class="form-control" id="class" readonly :value="item.nmfc_class_code">
          </div>
          <div class="form-group col-md-1">
            <label for="nmfc">NMFC</label>
            <input type="text" class="form-control" id="nmfc" readonly :value="item.nmfc_code">
          </div>
          <div class="form-group col-md-1">
            <label for="stackable">Stackable</label>
            <input type="text" class="form-control" id="nmfc" readonly :value="item.stackable">
          </div>
        </div>
          <div class="form-row">
          <div class="form-group col-md-1">
            <label for="spots">Spots</label>
            <input type="text" class="form-control" id="spots" readonly :value="item.req_spots">
          </div>
            <div class="form-group col-md-1">
              <label for="handlingUnits">Handling Units</label>
              <input type="text" class="form-control" id="handlingUnits" readonly :value="item.handling_units">
            </div>
            <div class="form-group col-md-1">
              <label for="length">Length</label>
              <input type="text" class="form-control" id="length" readonly :value="item.length">
            </div>
            <div class="form-group col-md-1">
              <label for="width">Width</label>
              <input type="text" class="form-control" id="width" readonly :value="item.width">
            </div>
            <div class="form-group col-md-1">
              <label for="width">Height</label>
              <input type="text" class="form-control" id="height" readonly :value="item.height">
            </div>
            <div class="form-group col-md-1">
              <label for="linearUnits">Linear Units*</label>
              <input type="text" class="form-control" id="linearUnits" readonly :value="item.linear_units">
            </div>
            <div class="form-group col-md-1">
              <label for="cubicUnits">Cubic Units*</label>
              <input type="text" class="form-control" id="cubicUnits" readonly :value="item.cubic_units">
            </div>
            <div class="form-group col-md-1">
              <label for="density">Density*</label>
              <input type="text" class="form-control" id="density" readonly :value="item.density">
            </div>
            <div class="form-group col-md-1">
              <label for="hazmat">Hazmat</label>
              <input type="text" class="form-control" id="cubicUnits" readonly :value="item.hazmat_details">
            </div>
        </div>
      </div>
          </b-card-body>
        </b-collapse>
      </b-card>
    </div>
  </div>


    <div class="form-row">
      <label class="container-fluid text-muted mt-5">Please review the details below before final submission</label>
      <div v-for="(item, index) in order.freight_details" v-bind:key="index">
      </div>
    </div>
  </form>
</div>
</template>
<script>
import OrderService from "@/common/orders.service";
export default {
  mounted() {
    this.orderId = this.$route.query.orderId;
    OrderService.GetFreightSummary(this.orderId)
    .then(data => {
      this.freightDetails = data;
      this.originZip = data[0].fromZip;
      this.destinationZip = data[0].toZip;
      this.freightDetails.forEach((item, index) => {
        item.itemHeader ='Click here to see freight detail: ' + (index + 1).toString();
      });
    });
  },
  data: function () {
    
    return {
      orderId: '',
      originZip: '',
      destinationZip: '',
      order: {},
      freightDetails:[],
      item: {},
      index: 0
    }
  }
}
</script>
<style>

</style>