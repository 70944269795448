<template>
  <div>
    <ViewIdentity name="Order Tracking"></ViewIdentity>
    <div class="container-fluid">
        <TrackingTable></TrackingTable>
    </div>
  </div>
</template>
<script>
import TrackingTable from "@/components/tables/TableTracking";
import ViewIdentity from "@/components/ViewIdentity";

export default {
  components: {TrackingTable, ViewIdentity}
}
</script>