<template>
  <div id="app">
    <router-view :key="$route.fullPath"></router-view>
  </div>
</template>
<script>

export default {
  name: 'App',
  watch: {
    '$route'(to) {
      document.title = to.meta.title || 'England Logistics'
    },
    immediate: true
  }
}
</script>