import axios from 'axios';

const BrokerService = {
    getDetails() {
        return axios.get("/Users/brokerDetails")
            .then(res => {
                return res.data;
            }).catch( error => {
                console.log(error);
            }          
            );
    }
}

export default BrokerService;