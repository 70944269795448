<template>
  <div class="dashboard-bg" style="position: relative;" >
    <ViewIdentity name=""></ViewIdentity>
    <form>
      <div class="container-fluid">
        <div class="row">
          <div class="column col-sm-12 col-md-12 col-lg-4">
            <h5 class="m-1 pt-2 text-dark">Orders</h5>
            <OrderStatusCard      
              :count-changed="(item) => countChanged(item)">
            </OrderStatusCard>


            <div class="d-flex justify-content-center mt-5">
              <a target="_blank" href="https://www.englandlogistics.com/one-initiative/">
                <img class="banner" :src="require(`../assets/onebanner.png`)">
              </a>
            </div>
          </div>
          <div class="column col-lg-4">
            <h5 class="m-1 pt-2 text-dark">Reports</h5>
            <div class="card p-1">
              <div class="m-2">
                <ul class="list-group">
                  <li
                    class="list-group-item d-flex justify-content-between"
                    @click="downloadReport('aging-report')"
                  >
                    Aging Report
                    <b-spinner
                      v-if="reportDownloading"
                      variant="secondary"
                      label="Spinning"
                    ></b-spinner>
                  </li>
                </ul>
              </div>
            </div>
            <h5 class="m-1 pt-2 text-dark">Invoices</h5>
            <OpenInvoices class="mt-1 pt-2"></OpenInvoices>
            
            <div class="d-flex justify-content-center mt-5">
              <div class="row" title="Thank you for your contribution to the One Initiative. Please click the One logo for details.">
                <b class="childrenFed" :style="{'padding-left': childrenFedPadding +'px'}">CHILDREN FED: </b>
                <div class="col-2">
                  <div class="countdown">
                    <div class="bloc-time sec row">
                      <div v-for="(item) in childrenFedArray" v-bind:key="item" class="ml-1">
                        <div class="figure sec sec-1">
                          <span class="top">{{item}}</span>
                          <span class="bottom">{{item}}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="column col-lg-4">
            <h5 class="m-1 pt-2 text-dark">Contact</h5>
            <BrokerDetails />
            <OrderMap class="mt-4" />
          </div>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import BrokerDetails from "@/components/BrokerDetails";
import OrderMap from "@/components/OrderMap";
import ViewIdentity from "@/components/ViewIdentity";
import OpenInvoices from "@/components/OpenInvoices";
import ReportsService from "@/common/reports.service";
import FileSaver from "file-saver";
import OrderStatusCard from "@/components/OrderStatusCard";
import OrderService from "@/common/orders.service";

export default {
  mounted() {
    this.countChanged();
  },
  components: {
    OpenInvoices,
    BrokerDetails,
    OrderMap,
    ViewIdentity,
    OrderStatusCard    
  },
  data: function() {
    return {
      reportDownloading: false,
      value: "$10,000",
      url: "https://www.crengland.com/one-initiative",
      start: null,
      end: null,
      childrenFedArray: 0,
      childrenFedPadding : 0

    };
  },

  methods: {
    countChanged(){
      this.childrenFedPadding = 284;
      OrderService.getFedChildren(
      ).then((item) => {
        var childrenFed = Math.floor(item/2);
        this.childrenFedArray = childrenFed.toString().split("");
        this.childrenFedPadding = 284 - this.childrenFedArray.length * 24;
      })
      .finally(()=> this.isBusy = false );
    },
    launchVersaPay: function() {
      // launch versa pay in a new tab or window.
      window.open("https://www.versapay.com/", "_blank");
    },
    downloadReport(reportName) {
      if (!this.reportDownloading) {
        this.reportDownloading = true;
        ReportsService.getAgingReport().then((blob) => {
          this.reportDownloading = false;
          let name = `${reportName}-${ReportsService.getDate()}`;
          FileSaver.saveAs(blob, name);
        });
      }
    },
  },
};
</script>
<style>
.list-group-item:hover {
  cursor: pointer;
  background-color: lightgrey;
}

.dashboard-bg {
  background-color: RGB(234,233,234);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  background-blend-mode: difference;
  height: 94vh;
}
.banner {
  max-width: 30vw;
  height: auto;
  opacity: .75;
  display: flex;
  align-items: flex-end;
  margin-left: 29vw;
  margin-top: 7vh;
}
.footer{
  width: 80vw;
  position: fixed;
  bottom: 0;
}
.childrenFed{
  margin-top: 14vh!important;
  float: right;
  white-space: nowrap;
  color: #424242;
}

.countdown {
  margin-top: 14vh!important;
  width: 720px;
  margin: 0 auto;
}
.countdown .bloc-time {
  float: left;
  margin-right: 45px;
  text-align: center;
}
.countdown .bloc-time:last-child {
  margin-right: 0;
}
.countdown .count-title {
  display: block;
  margin-bottom: 15px;
  font: normal 0.94em "Lato";
  color: #1a1a1a;
  text-transform: uppercase;
}
.countdown .figure {
  position: relative;
  float: left;
  height: 30px;
  width: 20px;
  margin-right: 10px;
  background-color: #424242;
  border-radius: 8px;
  -moz-box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.2), inset 2px 4px 0 0 rgba(255, 255, 255, 0.08);
  -webkit-box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.2), inset 2px 4px 0 0 rgba(255, 255, 255, 0.08);
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.2), inset 2px 4px 0 0 rgba(255, 255, 255, 0.08);
}
.countdown .figure:last-child {
  margin-right: 0;
}
.countdown .figure > span {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  font: normal 25px "Lato";
  font-weight: 700;
  color: whitesmoke;
}
.countdown .figure .top:after,
.countdown .figure .bottom-back:after {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.countdown .figure .top {
  z-index: 3;
  background-color: #424242;
  transform-origin: 50% 100%;
  -webkit-transform-origin: 50% 100%;
  -moz-border-radius-topleft: 10px;
  -webkit-border-top-left-radius: 10px;
  border-top-left-radius: 10px;
  -moz-border-radius-topright: 10px;
  -webkit-border-top-right-radius: 10px;
  border-top-right-radius: 10px;
  -moz-transform: perspective(200px);
  -ms-transform: perspective(200px);
  -webkit-transform: perspective(200px);
  transform: perspective(200px);
}
.countdown .figure .bottom {
  z-index: 1;
}
.countdown .figure .bottom:before {
  content: "";
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 50%;
  background-color: rgba(0, 0, 0, 0.02);
}
.countdown .figure .top{
  height: 50%;
  overflow: hidden;
  -moz-backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.countdown .figure .top-back {
  z-index: 4;
  bottom: 0;
  background-color: #424242;
  -webkit-transform-origin: 50% 0;
  transform-origin: 50% 0;
  -moz-transform: perspective(200px) rotateX(180deg);
  -ms-transform: perspective(200px) rotateX(180deg);
  -webkit-transform: perspective(200px) rotateX(180deg);
  transform: perspective(200px) rotateX(180deg);
  -moz-border-radius-bottomleft: 10px;
  -webkit-border-bottom-left-radius: 10px;
  border-bottom-left-radius: 10px;
  -moz-border-radius-bottomright: 10px;
  -webkit-border-bottom-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.countdown .figure .top-back span {
  position: absolute;
  top: -100%;
  left: 0;
  right: 0;
  margin: auto;
}

</style>
