import Vue from "vue";

const notify = Vue.mixin({
  methods: {
    notifyError(msg) {
      this.$bvToast.toast(`${msg}`, {
        title: "Error",
        autoHideDelay: 7000,
        variant: "danger",
        solid: true,
      });
    },
    notifySuccess(msg) {
      this.$bvToast.toast(`${msg}`, {
        title: "Success",
        autoHideDelay: 7000,
        variant: "success",
        solid: true,
      });
    },
    notifyInfo(msg) {
      this.$bvToast.toast(`${msg}`, {
        title: "Info",
        autoHideDelay: 7000,
        variant: "info",
        solid: true,
      });
    },
    notifyWarn(msg) {
      this.$bvToast.toast(`${msg}`, {
        title: "Warning",
        autoHideDelay: 7000,
        variant: "warning",
        solid: true,
      });
    },
  },
});

export default notify;
