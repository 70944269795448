<template>
  <div>
    <ViewIdentity name="Quote Review"></ViewIdentity>
    <div class="container-fluid">
      <QuoteTable></QuoteTable>
    </div>
  </div>
</template>
<script>
import QuoteTable from "@/components/tables/TableQuote";
import ViewIdentity from "@/components/ViewIdentity";

export default {
  components: {QuoteTable, ViewIdentity},
}
</script>