<template>
  <div class="mt-3 pl-1 bg-warning rounded">
    <div class="form-row">
      <div class="col m-12">
        <div class="form-row">
          <div class="form-group row col-3">
            <div class="col-6">
              <custom-select
                label="UN No."
                v-model="hazmatData.hazmatUnnaNbr"
                :items="hazmatUnnaNbr"
                :item-selected="(item) => {setHazmatUnnaNbr(item)}"
                item-key="hazmatUnnaNbr"
                item-key2="hazmatUnnaNbr"    
                :hideRequired="true"
                :stateVal="isValid(hazmatData.hazmatUnnaNbr)"
                :readonly="readonly"
                :typeAhead="true"
                :validValues="true"
                :validateExact="true"   
              ></custom-select>
            </div>
            <div class="col-6">
              <custom-select
                label="Hazmat Class"
                v-model="hazmatData.hazmatClass"
                :items="hazmatClass"
                :item-selected="(item) => {}"
                item-key="field_code"
                item-key2="field_code_desc"    
                :hideRequired="true"
                :stateVal="isValid(hazmatData.hazmatClass)"
                :readonly="readonly"
                :typeAhead="true"
                :validValues="true"
                :validateExact="true" 
              ></custom-select>
            </div>
          </div>
          <div class="form-group col-1">
            <label for="hmSubClass">Sub Class</label>
            <input
              type="text"
              class="form-control"
              id="hmSubClass"
              maxlength= 4 
              v-model="hazmatData.sub_class"
              :readonly="readonly"
            />
          </div>
          <div class="form-group col-2">
            <custom-select
              label="Packaging Group" 
              :hideRequired="true"
              :items="hazmatPacking"
              :item-selected="(item) => {}"
              item-key="field_code"
              item-key2="field_code_desc"   
              :stateVal= null
              :readonly="readonly"
              :typeAhead="true"
              :validValues="true"
              :validateExact="true"  
              v-model="hazmatData.hazmatPacking"
            ></custom-select>
          </div>
          <div class="form-group col-2">
            <custom-select
              label="ERG Number"
              :hideRequired="true"
              :items="hazmatErgNumber"
              :item-selected="(item) => {}"
              item-key="field_code"
              item-key2="field_code_desc"   
              :readonly="readonly"
              :stateVal="null"
              :typeAhead="true"
              :validValues="true"
              :validateExact="true"  
              v-model="hazmatData.hazmatErgNumber" 
            ></custom-select>
          </div>
          <div class="form-group form-check mr-4">
            <label class="form-check-label" for="hmPoison">Poison</label>
            <div class="mt-2">
              <input
                type="checkbox"
                class="checkbox"
                id="hmPoison"
                v-model="hazmatData.poison"
                :disabled="readonly"
              />
            </div>
          </div>
          <div class="row form-group col-3">
              <div class="col-6">
                <custom-select
                  label="Poison Zone"
                  :hideRequired="true"
                  :items="hazmatPoisonZone"
                  :item-selected="(item) => {}"
                  item-key="field_code"
                  :readonly="readonly"
                  :stateVal="null"
                  :typeAhead="true"
                  :validValues="true"
                  :validateExact="true"  
                  v-model="hazmatData.hazmatPoisonZone"
                ></custom-select>
              </div>
              
              <div class="form-group col-6">
                <label for="hmEmergencyNumber">Emergency Number</label>
                <b-form-input
                  type="text"
                  id="hmEmergencyNumber"
                  class="form-control"
                  v-model="hazmatData.emergency_phone"
                  @keyup="acceptNumber"
                  :readonly="readonly"
                  :state="isValid(hazmatData.emergency_phone)"
                />
              </div>
          </div>
          
          <div class="col md-1">
            <button v-if="!readonly" tabindex="-1"
              class="btn btn-primary float-right"
              title="Clear Hazmat fields"
              @click="() => {clear();}">
              X
            </button>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-md-6">
            <label for="hmProperShippingName">Proper Shipping Name</label>
            <b-form-input
              type="text"
              class="form-control"
              id="hmProperShippingName"
              maxlength= 256 
              v-model="hazmatData.shipping_name"
              :state="isValid(hazmatData.shipping_name)"
              :readonly="readonly"
            />
          </div>
          <div class="form-group col-md-3">
            <label for="hmContact">Contact</label>
            <b-form-input
              type="text"
              class="form-control"
              id="hmContact"
              maxlength= 64 
              :state="isValid(hazmatData.contact)"
              :readonly="readonly"
              v-model="hazmatData.contact"
            />
          </div>
          <div class="form-group col-md-2">
            <custom-select
              :avoidTyping="true"
              label="Contact Type"
              :hideRequired="true"
              :items="hazmatContactType"
              :item-selected="(item) => { hazmatData.hazmatContactType = item.field_code}"
              item-key="field_code_desc"
              item-key2="field_code"
              :readonly="readonly"
              :stateVal="isValid(hazmatData.hazmatContactType)"
              :typeAhead="true"
              :validValues="true"
              :validateExact="true"  
              v-model="hazmatData.hazmatContactTypeDesc"
            ></custom-select>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProductsService from "@/common/products.service";
import CustomSelect from "@/components/CustomSelect";

export default {
  props: {
    readonly: Boolean,
    showValidations: Boolean,
    existingData: {}
  },
  components: {
    CustomSelect,
  },
  emits: ["onChange"],
  data: function () {
    return {
      hazmatContactType: [],
      hazmatErgNumber: [],
      hazmatClass: [],
      hazmatPacking: [],
      hazmatPoisonZone: [],
      hazmatUnnaNbr: [],
      hazmatData: {
        contact: "",
        emergency_phone: "",
        poison: false,
        hazmatErgNumber: "",
        hazmatClass: "",
        sub_class: "",
        hazmatPacking: "",
        hazmatPoisonZone: "",
        hazmatUnnaNbr: "",
        hazmatContactType: "",
        hazmatContactTypeDesc: "",
        shipping_name: "",
      },
    };
  },
  watch: {
    $data: {
      handler: function(newVal) {
        this.$emit("onChange", newVal);
      },
      deep: true,
    }
  },
  mounted () {
    ProductsService.getHazmatClasses()
    .then(data => {
      this.hazmatClass = data;      
    });
    ProductsService.getHazmatErgNumber()
    .then(data => {
      this.hazmatErgNumber = data;      
    });
    ProductsService.getHazmatPacking()
    .then(data => {
      this.hazmatPacking = data;      
    });
    ProductsService.getHazmatPoisonZone()
    .then(data => {
      this.hazmatPoisonZone = data;      
    });
    ProductsService.getHazmatUnnaNbr()
    .then(data => {
      this.hazmatUnnaNbr = data;      
    });
    ProductsService.getHazmatContactType()
    .then(data => {
      this.hazmatContactType = data;      
    });
    if(this.existingData){
        this.hazmatData = this.existingData;
    }
  },
  methods: {
    clear(){
      this.hazmatData = {};
    },
    isValid(value) {
      if(this.showValidations && !value){        
        return false;
      }
      return null;
    },
    acceptNumber() {
      var x = this.hazmatData.emergency_phone.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      this.hazmatData.emergency_phone = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
    },
    setHazmatUnnaNbr(item){
      if(item){
        if(item.hazmatUnnaNbr)
          this.hazmatData.hazmatUnnaNbr = item.hazmatUnnaNbr;
        
        if(item.hazmatPacking)
          this.hazmatData.hazmatPacking = item.hazmatPacking;
        else
          this.hazmatData.hazmatPacking = '';
        if(item.hazmatClass)
          this.hazmatData.hazmatClass = item.hazmatClass;
        else
          this.hazmatData.hazmatClass = '';
        if(item.hazmatErgNumber)
          this.hazmatData.hazmatErgNumber = item.hazmatErgNumber;
        else
          this.hazmatData.hazmatErgNumber = '';
        if(item.shipping_name)
          this.hazmatData.shipping_name = item.shipping_name;
        else
          this.hazmatData.shipping_name = '';
      }
      else{
        this.hazmatData.hazmatPacking = '';
        this.hazmatData.hazmatClass = '';
        this.hazmatData.hazmatErgNumber = '';
        this.hazmatData.shipping_name = '';
      }
    }
  }
};
</script>

<style scoped>
  .checkbox{
    min-width: 16px;
    min-height: 16px;
  }
</style>