<template>
  <div class="row login-pane">
    <div class="container-fluid col-auto">
      <form class="bg-light border rounded form-signin p-3 mx-auto" v-on:submit.prevent="forgotPassword">
        <Logo full_logo="true" id="crest" class="d-block p-4"></Logo>
        <div class="alert alert-danger" role="alert" v-if="showError">
          There was an error resetting the password.
        </div>
        <div v-if="authenticating" class="d-flex justify-content-center m-4">
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
        <label for="inputUser"
               class="sr-only">Username</label>
        <input v-model="username" type="email" id="inputUser" class="form-control m-1" placeholder="Username">
        <button class="btn btn-lg btn-primary btn-block mt-4" type="submit">Submit</button>
        <button class="btn btn-lg btn-secondary btn-block" @click="$router.push({ name: 'login' })">Back</button>
      </form>
    </div>
  </div>
</template>

<script>
import AuthService from "@/common/auth.service";
import Logo from '@/components/Logo';

export default {
  components: {Logo},
  mounted() {
  },
  data: function () {
    return {
      username: '',
      showError: false,
      authenticating: false
    }
  },
  methods: {
    forgotPassword() {
      this.authenticating = true;
      AuthService.forgotPassword(this.username)
      .then(() => {
        setTimeout(() => {
          this.notifyInfo(`An email has been sent to reset your password`)
        }, 100);
        this.$router.push("login");
      }).catch(err => {
        console.log(err)
        this.showError = true;
      }).finally(() => {
        this.authenticating = false;
      })
    }
  }
};
</script>
<style scoped>
#crest{
  max-width: 400px;
  left: 50%;
}
.login-pane {
  margin-top: 10rem;
}
</style>