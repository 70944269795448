<template>
  <div class="row login-pane">
    <div class="container-fluid col-auto">
      <form class="bg-light border rounded form-signin p-3 mx-auto" v-on:submit.prevent="signUp">
        <div v-if="isBusy" class="d-flex justify-content-center m-4">
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
        <div class="m-3">
          <div class="row">
            <label for="" >Contact Name</label>
            <b-form-input
              type="text"
              class="form-control m-1"
              v-model="signUpData.contactName" 
              id="contactName" 
              :state="isValid(signUpData.contactName)"
            />
          </div>
          <div class=" row">
            <label for="" >Company Name</label>
            <b-form-input
              type="text"
              class="form-control m-1"
              v-model="signUpData.companyName" 
              id="companyName" 
              :state="isValid(signUpData.companyName)"
            />
          </div>
          <div class=" row">
            <label for="" >Phone</label>
            <b-form-input
              type="text"
              class="form-control m-1"
              @keyup="acceptNumber"
              v-model="signUpData.phone" 
              id="phone" 
              :state="isValid(signUpData.phone)"
            />
          </div>
          <div class=" row">
            <label for="" >Email Address</label>
            <b-form-input
              type="text"
              class="form-control m-1"
              v-model="signUpData.emailAddress" 
              id="emailAddress" 
              :state="isValid(signUpData.emailAddress)"
            />
          </div>
          <div class=" row">
            <label for="" >England Logistics Sales Rep</label>
            <b-form-input
              type="text"
              class="form-control m-1"
              v-model="signUpData.salesRep" 
              id="salesRep" 
              :state="null"
            />
          </div>
          <div class="row mt-4">
            <button class="btn btn-lg btn-primary btn-block" type="submit">Sign up</button>
            <button class="btn btn-lg btn-secondary btn-block" @click="$router.push({ name: 'login' })">Back</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import AuthService from "@/common/auth.service";

export default {
  mounted() {
  },
  data: function () {
    return {
      submitted: false,
      isBusy: false,
      signUpData : {
        contactName: '',
        companyName: '',
        phone: '',
        emailAddress: '',
        salesRep: ''
      }
    }
  },
  methods: {
    signUp() {
      this.submitted = true;
      if(this.validate()){
        this.isBusy = true;
        AuthService.signUp(this.signUpData)
        .then(() => {
          this.notifySuccess('Thank you, a link for a login will be sent to the email address provided within 1 business day');
          this.signUpData = {
            contactName: '',
            companyName: '',
            phone: '',
            emailAddress: '',
            salesRep: ''
          };
          this.submitted = false;
          
        }).catch(err => {
          console.log(err)
          this.showError = true;
        }).finally(() => {
          this.isBusy = false;
        })
      }
    },
    isValid(value){
      if (!value && this.submitted) {
        return false;
      }
      return null;
    },
    acceptNumber() {
      var x = this.signUpData.phone.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      this.signUpData.phone = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
    },
    validateEmail() {
       var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      return validRegex.test(String(this.signUpData.emailAddress).toLowerCase());
    },
    validate() {
      if(!this.signUpData.contactName || 
         !this.signUpData.companyName || 
         !this.signUpData.phone || 
         !this.signUpData.emailAddress){
        this.notifyError("Please fill out the requierd fields")
        return false;
      }
      else{
        if(!this.validateEmail()){
          this.notifyError("Invalid emailAddress format")
          return false;
        }
        if(this.signUpData.phone.length < 14){
          this.notifyError("Invalid phone")
          return false;
        }
      }
      return true;
    }
  }
};
</script>
<style scoped>
#crest{
  max-width: 400px;
  left: 50%;
}
.login-pane {
  margin-top: 10rem;
}
</style>