<template>
  <div class=" border mx-auto d-inline-flex rounded px-3">
    <label for="toggle_button">
      <span v-if="currentState">On</span>
      <input type="checkbox" id="toggle_button" v-model="checkedValue">
      <span v-if="! currentState">Off</span>
    </label>
  </div>
</template>
<script>
export default {
  data() {
    return {
      currentState: false
    }
  },
  computed: {
    isActive() {
      return this.currentState;
    },
    checkedValue: {
      get() {
        return this.currentState
      },
      set(newValue) {
        this.currentState = newValue;
      }
    }
  }
}
</script>
<style scoped>

</style>