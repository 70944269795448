<template>
  <div>
    <ViewIdentity name="Reports"></ViewIdentity>
    <div class="container">
      <Alerts
        :msg="alertMsg"
        :show="showAlert"
        :variant="variant"
        :dismissible="true"
        @dismissed="showAlert = false"
      />
      <div class="row">
        <div class="col">
          <ReportCard name="Aging Report" />
        </div>
        <div class="col">
          <ReportCard name="Booked Loads" />
        </div>
        <div class="col">
          <ReportCard name="Quotes" />
        </div>
      </div>
      <div class="row">
        <div class="col">
          <ReportCard name="Tracking" />
        </div>
        <div class="col">
          <ReportCard name="Claims" />
        </div>
        <div class="col">
          <ReportCard name="On-time Performance" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ViewIdentity from "@/components/ViewIdentity";
import ReportCard from "@/components/CardReport";
import Alerts from "@/components/Alerts";

export default {
  components: { ReportCard, ViewIdentity, Alerts },
  data() {
    return {
      alertMsg: "",
      showAlert: false,
      variant: "success",
    };
  },
  mounted() {
    this.showAlert = true;
    this.alertMsg = "This is the dummy message";
  },
};
</script>
<style>
.report {
  margin: 1rem;
  padding: 1rem;
}

.card_button {
  width: 200px;
}
</style>
