<template>
  <div class="my-10 mx-3 pt-3 text-muted">
    <h3>{{name}}</h3>
  </div>
</template>
<script>
export default {
  name: '',
  props: {
    name: String
  }
}
</script>