<template>
  <div class="card mt-5 container-fluid col-auto" style="width: 18rem;">
  <Logo></Logo>
    <div class="card-body rounded">
      <h5 class="card-title">404 PAGE NOT FOUND</h5>
      <p class="card-text text-center">Page not found</p>
      <router-link to="/Home" class="mx-auto justify-content-center d-flex">Return Home</router-link>
    </div>
  </div>
</template>
<script>
import Logo from "@/components/Logo.vue";
export default {
  components: {Logo},
}
</script>