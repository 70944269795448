<template>
  <div>
    <ViewIdentity name="Import"></ViewIdentity>

  </div>
</template>
<script>
import ViewIdentity from "@/components/ViewIdentity";

export default {
  components: {ViewIdentity}
}
</script>