<template>
  <div class="form-group">
    <label for="selectInput" v-if="label">
      {{ label }} <span v-if="!hideRequired" class="text-danger font-weight-bold">*</span>
    </label>
    <b-form-input
      :id="label"
      :placeholder="placeholder"
      :value="this.$attrs.value"
      @input="updateQueryText"
      @focus="show = true"
      @blur="(show = false), validate(), $emit('onBlur')"
      :state="stateVal"
      :required="requiredVal"
      autocomplete="nope" 
      :readonly="readonly"
      :size="size"
      @keypress="checkTyping"
    />
    <b-spinner v-if="isBusy" small aria-hidden="true"></b-spinner>
    <ul v-if="!readonly"
      class="list-group position-absolute zIndex scrollable"
      v-show="show && filteredItems && filteredItems.length"
    >
      <li
        class="list-group-item list-group-item-action p-3"
        v-for="(item, index) in filteredItems"
        v-bind:key="index"
        @mousedown="selected(item)"
      >
        <span>{{ item[itemKey] }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "CustomSelect",
  props: {
    defaultListLabel: String,
    items: Array,
    itemKey: String,
    itemKey2: String,
    label: String,
    itemSelected: Function,
    stateVal: Boolean,
    requiredVal: Boolean,
    validValues: Boolean,
    validateExact: Boolean,
    typeAhead: Boolean,
    readonly: Boolean,
    isBusy: Boolean,
    size: String,
    hideRequired: Boolean,
    placeholder: String,
    avoidTyping: Boolean
  },
  emits: ["update:modelValue"],
  
  watch: {
    items: {
      handler: function() {
        this.filteredItems = this.items;
      }
    }
  },
  mounted() {
    if (!this.items) {
      this.$emit("setItem", [{ description: this.defaultListLabel }]);
    }
    this.updateQueryText(this.$attrs.value);
    // // toggle list so elements are hidden until input is selected.
    // this.toggleList();
  },
  data() {
    return {
      filteredItems: [],
      cachedResults: [],
      show: false,
    };
  },
  methods: {
    checkTyping(event){
      if(this.avoidTyping){
        event.preventDefault();
      }
    },
    selected(item) {
      // set selected text to that of the selected item
      this.$emit("input", item[this.itemKey]);

      // notify parent of selection
      this.itemSelected(item);
      this.show = false;
    },
    validate(){
      var exact = this.items.filter(f=> {
        if(this.itemKey2){
          if(f[this.itemKey] && f[this.itemKey2])
            return f[this.itemKey]?.toLowerCase() === this.$attrs.value?.toLowerCase() || f[this.itemKey2]?.toLowerCase() === this.$attrs.value?.toLowerCase() ;
        }
        else{
          if(f[this.itemKey])
            return f[this.itemKey]?.toLowerCase() === this.$attrs.value?.toLowerCase();
        }
      });
      if(this.validateExact ){
        if(exact.length !== 1){
          this.$attrs.value = '';
          this.filteredItems = this.items;
        }
      }
      else{
        if(this.validValues && this.filteredItems.length !== 1){
          this.$attrs.value = '';
          this.filteredItems = this.items;
        }
      }
      
      if(this.filteredItems.length === 1) {
        this.$attrs.value = this.filteredItems[0][this.itemKey];
        this.selected(this.filteredItems[0]);        
      }
      this.$emit("input", this.$attrs.value);
    },
    updateQueryText(value) {
      this.$emit("input", value);
      if(this.typeAhead){
        var itemKey = this.itemKey;
        var itemKey2 = this.itemKey2;
        this.filteredItems = this.items.filter(f=> {
          if(itemKey2){
            if(f[itemKey] && f[itemKey2])
              return f[itemKey].toLowerCase().indexOf(value.toLowerCase()) > -1 || f[itemKey2].toLowerCase().indexOf(value.toLowerCase()) > -1 ;
          }
          else{
            if(f[itemKey] && value)
              return f[itemKey].toLowerCase().indexOf(value.toLowerCase()) > -1;
          }
        });
      }
    },
  },
};
</script>

<style scoped>
.zIndex {
  z-index: 1001;
}

.scrollable {
  width: 95%;
  height: auto;
  max-height: 300px;
  text-align: left;
  overflow-x: hidden;
  overflow-y: auto;
  box-shadow: 4px 4px 7px -2px #cecece;
  border-radius: 0;
}
li {
  background-color: #f8f8f8;
}
li:hover {
  cursor: pointer;
  background-color: #2185e3;
  color: white;
}
</style>
