import axios from 'axios';

const LegalService = {
    getLegal() {
        return axios.get("/Users/getAppText")
            .then(res => {
                return res.data;
            });
    }
}

export default LegalService;