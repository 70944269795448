<template>
  <div class="row login-pane">
    <div class="container-fluid col-auto">
      <form class="bg-light border rounded form-signin p-3 mx-auto" v-on:submit.prevent="resetPassword">
        <Logo full_logo="true" id="crest" class="d-block p-4"></Logo>
        <div class="alert alert-danger" role="alert" v-if="showError">
          There was an error resetting the password.
        </div>
        <div v-if="authenticating" class="d-flex justify-content-center m-4">
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
        <b-form-input
          type="password"
          class="form-control m-1"
          v-model="password" 
          id="inputPassword" 
          placeholder="Password"
          :state="isValid(password)"
        />
        <b-form-input
          type="password"
          class="form-control m-1"
          v-model="passwordConfirm" 
          id="inputPassword" 
          placeholder="Confirm Password"
          :state="isValid(passwordConfirm)"
        />
        <div class="mt-2">
          <button class="btn btn-lg btn-primary btn-block" type="submit">Submit</button>
          <button class="btn btn-lg btn-secondary btn-block" @click="$router.push({ name: 'login' })">Back</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import AuthService from "@/common/auth.service";
import Logo from '@/components/Logo';

export default {
  components: {Logo},
  mounted() {
    this.token = this.$route.query.token;  
  },
  data: function () {
    return {
      submitted: false,
      password: '',
      passwordConfirm: '',
      token: '',
      showError: false,
      authenticating: false
    }
  },
  methods: {
    resetPassword() {
      this.submitted = true;
      if(this.validate()){
        this.authenticating = true;
        AuthService.resetPassword(this.token, this.password)
        .then(() => {
            
          setTimeout(() => {
            this.notifyInfo(`Password has been successfully updated`)
          }, 100);
          this.$router.push("login");
        }).catch(err => {
          this.notifyError(err.response.data)
          this.showError = true;
        }).finally(() => {
          this.authenticating = false;
        })
      }
    },
    isValid(value){
      if (!value && this.submitted) {
        return false;
      }
      return null;
    },
    validate() {
      if(!this.password || !this.passwordConfirm){
        this.notifyError("Passwords are required")
        return false;
      }
      else{
        if(this.password !== this.passwordConfirm){
          this.notifyError("The passwords are not equal")
          return false;
        }
        else
        {
          if(this.password.includes('#') || this.password.includes('&')  || this.password.includes('[')  || this.password.includes(']') ){
            this.notifyError("Invalid characters # & [ ] present on the password. ")
            return false;
          }

        }
      }

      return true;
    }
  }
};
</script>
<style scoped>
#crest{
  max-width: 400px;
  left: 50%;
}
.login-pane {
  margin-top: 10rem;
}
</style>