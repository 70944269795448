import Vue from "vue";
import Router from "vue-router";
import Login from "@/views/Login";
import ResetPassword from "@/views/ResetPassword";
import SignUp from "@/views/SignUp";
import ForgotPassword from "@/views/ForgotPassword";
import Home from "@/views/Home";
import Dashboard from "@/views/Dashboard";
import Reports from "@/views/Reports";
import Documents from "@/views/Documents";
import Quote from "@/views/Quote";
import Order from "@/views/Order";
import Review from "@/views/QuoteReview";
import Tracking from "@/views/Tracking";
import Help from "@/views/Help";
import PageNotFound from "@/views/404.vue";
import Summary from "@/views/FreightSummary";
import Booked from "@/views/OrderReview";
import Tools from "@/views/Tools";
import Import from "@/views/Import";
import Pay from "@/views/Pay";

Vue.config.productionTip = false

Vue.use(Router);

export default new Router({
    mode: 'history',
    linkActiveClass: 'is-active',
    routes: [
        {
            path: "/login",
            name: "login",
            component: Login,
            meta: {
                title: "Login"
            }
        },
        {
            path: "/resetPassword",
            name: "resetPassword",
            component: ResetPassword,
            meta: {
                title: "Reset Password"
            }
        },
        {
            path: "/signUp",
            name: "signUp",
            component: SignUp,
            meta: {
                title: "Sign Up"
            }
        },
        {
            path: "/forgotPassword",
            name: "forgotPassword",
            component: ForgotPassword,
            meta: {
                title: "Forgot Password"
            }
        },
        {
            path: "/home",
            component: Home,
            meta: {
                title: "Home"
            },
            beforeEnter: (to, from, next) => {
                // Add route name to page title
                document.title = to.meta.title
                // verify they've been authenticated before entering the dashboard otherwise redirect to login page.
                if (!localStorage.getItem("token")) {
                    next("/login");
                } else {
                    next();
                }
            },
            children: [
                {
                    path: "/dashboard",
                    name: "dashboard",
                    component: Dashboard,
                    meta: {
                        title: "Dashboard"
                    }
                },
                {
                  path: "/reports",
                  name: "reports",
                  component: Reports,
                    meta: {
                        title: "Reports"
                    }
                },
                {
                  path: "/documents",
                  name: "documents",
                  component: Documents,
                    meta: {
                        title: "Documents"
                    }
                },
                {
                    path: "/quote",
                    name: "quote",
                    component: Quote,
                    meta: {
                        title: "Create Quote"
                    }
                },
                {
                    path: "/order",
                    name: "order",
                    component: Order,
                    meta: {
                        title: "Order"
                    }
                },
                {
                    path: "/booked",
                    name: "booked",
                    component: Booked,
                    meta: {
                        title: "Booked Orders"
                    }
                },
                {
                    path: "/review",
                    name: "review",
                    component: Review,
                    meta: {
                        title: "Review Quotes"
                    }
                },
                {
                    path: "/tracking",
                    name: "tracking",
                    component: Tracking,
                    meta: {
                        title: "Tracking Orders"
                    }
                },
                {
                    path: "/pay",
                    name: "pay",
                    component: Pay,
                    meta: {
                        title: "Pay a Bill"
                    }
                },
                {
                    path: "/help",
                    name: "help",
                    component: Help,
                    meta: {
                        title: "Help"
                    }
                },
                {
                    path: "/summary",
                    name: "summary",
                    component: Summary,
                    meta: {
                        title: "Freight Summary"
                    }
                },
                {
                    path: "/tools",
                    name: "tools",
                    component: Tools,
                    meta: {
                        title: "Tools"
                    }
                },
                {
                    path: "/import",
                    name: "import",
                    component: Import,
                    meta: {
                        title: "Import"
                    }
                },
                {
                    path: "/",
                    redirect: "/dashboard"
                }
            ]
        },
        {
            path: '/',
            redirect: '/home'
        },
        {path: "*", component: PageNotFound}
    ]
})
