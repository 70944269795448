<template>
  <div>
    <ViewIdentity name="Help"></ViewIdentity>
  </div>
</template>
<script>
import ViewIdentity from "@/components/ViewIdentity";

export default {
  components: {ViewIdentity},
}
</script>