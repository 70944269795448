<template>
  <div>
    <ViewIdentity name="Freight Summary"></ViewIdentity>
    <FreightSummaryForm></FreightSummaryForm>
    <Rates></Rates>
  </div>
</template>
<script>
import FreightSummaryForm from "@/components/forms/FormFreightSummary";
import ViewIdentity from "@/components/ViewIdentity";
import Rates from "@/components/Rates";
export default {
  components: {ViewIdentity,FreightSummaryForm,Rates}
}
</script>