import axios from "axios";

const ReportsService = {
  getDate() {
    const todayDate = new Date();
    const myYear = todayDate.getFullYear(); //return the four digit year
    const myMonth = todayDate.getMonth(); //return the month
    const myDate = todayDate.getDate(); //return the day of the month
    const myNewDate = `${myMonth.toString()}-${myDate.toString()}-${myYear.toString()}`;
    return myNewDate;
  },
  getAgingReport() {
    return axios.request({url: "/Reports/getAgedAR", responseType: "blob"})
    .then((res) => {
      return res.data;
    })
    .catch( error => {
      console.log(error);
    });
  },
  getBOLReport(orderId) {
    return axios.request({url: `/Reports/getBillOfLadingReport?OrderId=${orderId}`, responseType: "blob"})
    .then((res) => {
      return res.data;
    })
    .catch( error => {
      console.log(error);
    })
  },
};

export default ReportsService;
