<template>
  <div>
    <b-alert
      :show="dismissCountDown"
      :dismissible="dismissible"
      :variant="variant"
      @dismissed="$emit('dismissed')"
      @dismiss-count-down="countDownChanged"
      fade
    >
      <p>
        {{ msg }}
      </p>
    </b-alert>
  </div>
</template>
<script>
export default {
  props: ["msg", "show", "variant", "dismissible"],
  data() {
    return {
      dismissSecs: 10,
      dismissCountDown: 0,
    };
  },
  watch: {
    show(newVal) {
      if (newVal) this.dismissCountDown = 5;
    },
  },
  methods: {
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
  },
};
</script>
