<template>
  <nav id="side-drawer" class="h-100 text-white bg-dark">
    <div class="h-100 overflow-auto text-white bg-dark">
      <ul class="list-unstyled">
        <li class="sidebar-list-item">
          <router-link to="/dashboard" class="list-group-item list-group-item-action text-white bg-dark">
            <span v-show="$root.showSideBar"><i class="fa fa-home pr-1"></i>Home </span>
            <span class="fa fa-home" title="Home" v-show="!$root.showSideBar"></span>
          </router-link>
        </li>
        <li class="sidebar-list-item">
          <router-link to="/quote" class="list-group-item list-group-item-action text-white bg-dark">
            <span v-show="$root.showSideBar"><i class="fa fa-file pr-1"></i>Create Quote </span>
            <span v-show="!$root.showSideBar" title="Create Quote"><i class="fa fa-file pr-1"></i></span>
          </router-link>
        </li>
        <li class="sidebar-list-item">
          <router-link to="/review" class="list-group-item list-group-item-action text-white bg-dark">
            <span v-show="$root.showSideBar"><i class="fa fa-eye pr-1"></i>Review Quotes </span>
            <span class="fa fa-eye" title="Review Quotes" v-show="!$root.showSideBar"></span>
          </router-link>
        </li>
        <li class="sidebar-list-item">
          <router-link to="/booked" class="list-group-item list-group-item-action text-white bg-dark">
            <span v-show="$root.showSideBar"><i class="fa fa-book pr-1"></i>Booked Orders </span>
            <span class="fa fa-book" title="Booked Orders" v-show="!$root.showSideBar"> </span>
          </router-link>
        </li>
      </ul>
      <Logo full_logo="true" id="crest" class="p-4" v-show="$root.showSideBar"></Logo>
    </div>
  </nav>
</template>
<script>
import Logo from "@/components/Logo";

export default {
  data() {
    return {
      showing: true
    };
  },
  mounted() {
  },
  components: {Logo},
  methods: {
  }
}
</script>
<style scoped>
.router-link-exact-active {
  font-weight: 600;
}
#side-drawer {
  width: 15vw;
  top: 0px;
  z-index: 1032; /*z-index of standard bootstrap navbar is 1030 + 1 offset due to side-drawer-void*/
  transition: left 0.25s ease;
}

#side-drawer-void {
  background: rgba(0, 0, 0, .6);
}

#crest{
  bottom: 0;
  list-style-type: none;
  display: flex;
  align-items: flex-end;
  height: 70%!important;
  width: 15vw;
  position: fixed;
}
</style>