import axios from 'axios';

const LocationService = {
    search() {
        return axios.get("/Location")
        .then(res => {
            return res.data;
        });
    }
}

export default LocationService;