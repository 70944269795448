<template>
  <div class="w-auto">
    <TopNav/>
    <div class="d-flex align-items-stretch">
      <div class="sidebar" id="sidebar">
        <Navigation/>
      </div>
      <div id="main" class="page-holder bg-gray-100 correctSize">
        <transition name="fade">
          <router-view/>
        </transition>
      </div>
    </div>
  </div>
</template>
<script>
import TopNav from "@/components/navigation/TopNav";
import Navigation from "@/components/navigation/Navigation";

export default {
  components: {Navigation, TopNav}
}
</script>
<style>
.fade-enter-active, .fade-leave-active {
  transition: opacity .3s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.correctSize{
  height: 94vh!important;
  width: 85vw;
  overflow: auto;
  background-color: RGB(234,233,234);
}
</style>