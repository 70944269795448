import axios from 'axios';

const AuthService = {
    login(username, password) {
        return axios.post("/Users/authenticate", 
        {
            user: username,
            password: password
        }).then(res => {
            return res.data;
        });
    },
    forgotPassword(username) {
        return axios.get(`/Users/forgotPassword?userEmail=${username}`)
        .then(res => {
            return res.data;
        });
    },
    resetPassword(token, password) {
        return axios.post("/Users/resetPassword", 
        {
            token: token,
            password: password
        }).then(res => {
            return res.data;
        });
    },
    signUp(signUpData) {
        return axios.post("/Users/signUp", signUpData)
        .then(res => {
            return res.data;
        });
    },
    
}

export default AuthService;