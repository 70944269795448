import axios from "axios";
let cancelToken;

const SearchService = {
  search(query) {
    
    //Check if there are any previous pending requests
    if (typeof cancelToken != typeof undefined) {
      cancelToken.cancel("Operation canceled due to new request.")
    }
    //Save the cancel token for the current request
    cancelToken = axios.CancelToken.source();

    return axios.get(`/Order/Search?search=${query}`, {cancelToken: cancelToken.token})
      .then((res) => {
        return res.data;
      });
  },
};

export default SearchService;
