<template>
  <div class="mt-5">
    <img v-if="full_logo" class="img-fluid" src="@/assets/fullLogo.png" alt="">
    <img v-else class="img-fluid" src="@/assets/logo.png" alt="">
  </div>
</template>
<script>
export default {
  props: ['full_logo']
}
</script>