import axios from 'axios';

const QuoteService = {
    getQuotes(start, end, orderId) {
        return axios.get(`/Order/GetQuotes?start=${start}&end=${end}&orderId=${orderId}`)
            .then(res => {
                return res.data;
            });
    },
    getCarrierRates(orderId) {
        return axios.get(`/Carrier?orderId=${orderId}`)
        .then(res => {
            return res.data;
        })
    },    
    getCarrierRatesAccepted(orderId) {
        return axios.get(`/Carrier/GetAccepted?orderId=${orderId}`)
        .then(res => {
            return res.data;
        })
    },  
    getCarrierRatesRestrictions(carriers) {
        return axios
        .post(`/Carrier/GetCarrierRestrictions`,JSON.parse(JSON.stringify(carriers)))
        .then((res) => {
        return res.data;
        })
        .catch(function(err) {
            console.log(err);
        });
    },    
    acceptCarrierRate(quoteId) {
        return axios.post(`/Carrier/Accept?quoteId=${quoteId}`)
        .then(res => {
            return res;
        })
    },
}

export default QuoteService;