<template>
  <div>
      <div class="input-group">
        <b-form-input
          v-bind:placeholder="label"
          :value="this.$attrs.value"
          @input="updateQueryText"
          @blur="$emit('checkValidity'); hideDropDown();"
          @focus="showList = true;"
          :state="isValid(this.$attrs.value)"
          required
          :readonly="readonly"
          autocomplete="nope"
          ref="searchBar"
          :maxlength="40"
        />
        <button v-if="!readonly" class="btn btn-primary ml--5" tabindex="-1" id="button-addon2" @click="() => {search();}">
          <div
            v-if="spinner"
            class="spinner-border spinner-border-sm text-light"
            role="status"
          >
            <span class="sr-only">Loading...</span>
          </div>
          <i v-if="!spinner" class="fas fa-search"></i>
        </button>
        <button v-if="!readonly && canDelete" class="btn btn-danger"  title="Clear Company Information"
          @click="() => {selected();}"
        >
           X 
        </button>
      </div>
    <b-alert
      :show="dismissCountDown"
      dismissible
      variant="danger"
      @dismissed="dismissCountDown = 0"
      @dismiss-count-down="countDownChanged"
      class="fixed-top w-25 mx-auto"
    >
      {{ alertError }}
    </b-alert>
    <ul v-if="!readonly" v-show="showList" class="list-group position-absolute zIndex scrollable">
      <li
        class="list-group-item list-group-item-action p-3"
        v-for="(item, index) in searchResults"
        v-bind:key="index"
        v-on:click="selected(item)"
      >
        <span>
          <i class="fa fa-map-marker-alt" /> {{ item.name }} 
        </span>
        <div>
          <span class="badge badge-primary float-right">{{ item.city }} ({{item.state}})</span>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import LocationService from "@/common/locations.service";

export default {
  name: "LocationSearchBar",
  props: {
    label: String,
    locationSelected: Function,
    requiredId: Boolean,
    readonly: Boolean,
    canDelete: Boolean,
    showValidationsAcceptQuote: Boolean,
    showValidations: Boolean,
    saveLocation: Boolean
  },
  emits: ["update:modelValue"],
  data() {
    return {
      searchResults: [],
      cachedResults: [],
      dismissSecs: 8,
      dismissCountDown: 0,
      showAlert: false,
      alertError: null,
      spinner: false,
      showList: false
    };
  },
  methods: {
    isValid(value){
      if(this.showValidations && this.saveLocation && !value){
        return false;
      }
      if(this.showValidationsAcceptQuote && !value){
        return false;
      }
      if(this.showValidationsAcceptQuote && value.trim() === ''){
        return false;
      }
      return null;
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    search() {
      this.spinner = true;
      LocationService.search(this.$attrs.value)
        .then((res) => {
          this.searchResults = res;
          this.spinner = false;
          this.showList = true;
        })
        .catch((error) => {
          console.log(error);
          this.showList = false;
          this.dismissCountDown = this.dismissSecs;
          this.alertError = error;
          this.spinner = false;
        })
        .finally(() => (this.showAlert = true));
        const searchBarRef = this.$refs.searchBar;
        searchBarRef.focus();
    },
    selected(location) {
      this.locationSelected(location);
      this.cachedResults = this.searchResults;
      this.searchResults = [];
    },
    hideDropDown: function(){
      setTimeout(() => {
         this.showList = false; 
      }, 1000)
    },
    updateQueryText(value) {
      this.$emit("inputUpdate", value);
    },
  },
};
</script>

<style scoped>
.zIndex {
  z-index: 1001;
}

.scrollable {
  height: auto;
  max-height: 300px;
  min-width: 50%;
  text-align: left;
  overflow: hidden;
  overflow-y: scroll;
}

li:hover {
  background-color: #2185e3;
  color: white;
}
</style>
