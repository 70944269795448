<template>
  <div>
    <ViewIdentity name=""></ViewIdentity>
    <b-overlay :show="dataLoaded == false" rounded="lg" opacity="0.6">
    <h3 class="my-10 mx-3 pt-3 text-muted" v-if="dataLoaded">Order Detail
      <span class="h5 ml-4 text-muted"> ID: {{orderData.order.id}} </span> 
      <span class="ml-4 h5 text-muted"> Status: {{orderData.order.status}}</span>
      <span class="ml-4 h5 text-muted"> PRO Number: {{orderData.order.pro_nbr}}</span>
    </h3>
    <div class="container-fluid" v-if="dataLoaded" >
      <Quote :readonly="true" :orderData="orderData"></Quote>
      
      <div class="container-fluid">
        <div class="ml-1 px-4">
          <div
            class="mt-3 h5 text-primary"
            :class="showTrackingDetails ? null : 'collapsed'"
            :aria-expanded="showTrackingDetails ? 'true' : 'false'"
            aria-controls="collapse-tracking"
          >
            <i
              @click="showTrackingDetails = !showTrackingDetails"
              :class="showTrackingDetails ? 'fa fa-minus fa-xs' : 'fa fa-plus fa-xs'"
              role="button"
            ></i>
            Tracking Details
          </div>
          <div id="trackingsection" ref="trackingsection">
          <b-collapse
            class="form-row"
            id="collapse-tracking"
            v-model="showTrackingDetails"
          >
            <TrackingTable></TrackingTable>
          </b-collapse>
          </div>
          <div
            class="mt-3 h5 text-primary"
            :class="showCarrierDetails ? null : 'collapsed'"
            :aria-expanded="showCarrierDetails ? 'true' : 'false'"
            aria-controls="collapse-tracking"
          >
            <i
              @click="showCarrierDetails = !showCarrierDetails"
              :class="showCarrierDetails ? 'fa fa-minus fa-xs' : 'fa fa-plus fa-xs'"
              role="button"
            ></i>
            Carrier Details
          </div>
          <b-collapse
            id="collapse-tracking"
            v-model="showCarrierDetails"
          >
            <Rates v-if="isQuote"></Rates>
            <CarrierDetails v-if="!isQuote"></CarrierDetails>
          </b-collapse>
          <div class="mt-3 mb-3">
            <b-button 
                @click="getBOLReport()"
                class="mr-2"
            >
              Generate BOL
            </b-button>
          </div>
        </div>
      </div>
    </div>
    </b-overlay>
  </div>
</template>
<script>
import Quote from "@/views/Quote";
import ViewIdentity from "@/components/ViewIdentity";
import TrackingTable from "@/components/tables/TableTracking";
import Rates from "@/components/Rates";
import CarrierDetails from '@/components/CarrierDetails.vue';
import OrderService from "@/common/orders.service";
import ReportsService from "@/common/reports.service";
import FileSaver from "file-saver";

export default {
  components: {Quote, Rates, TrackingTable, CarrierDetails, ViewIdentity},
  data(){
    return {
      orderData: {},
      dataLoaded:  false,
      isQuote: false,
      showTrackingDetails: true,
      showCarrierDetails: true,
      reportDownloading: false
    };
  },
  beforeMount() {
    
    var orderId = this.$route.query.orderId;
    var tracking = this.$route.query.tracking;
  
    OrderService.getOrderByID(orderId)
      .then((data) => {
        data.order.pro_nbr = 'N/A';
        if(data.order.movements && data.order.movements.length > 0){
          data.order.pro_nbr = data.order.movements[0].pro_nbr;
        }
        this.orderData = data;
        this.dataLoaded = true;
        this.isQuote = data.order.status ==="ORDER_STATUS_QUOTE";
        if(tracking === 'true'){
          setTimeout(() => {
            var elmnt = document.getElementById("trackingsection");
            elmnt.scrollIntoView();
          }, 300)
        }
      })
      .catch((err) => {
        console.log(err);
        this.notifyError("There was an error retrieving the quote");
      })
      .finally(() => {
        this.isBusy = false;
      });
  },
  methods: {
    // download bill of landing pdf
    getBOLReport() {
      if (!this.reportDownloading) {
        const orderId = this.orderData.order.id;
        this.reportDownloading = true;
        ReportsService.getBOLReport(orderId).then((blob) => {
          this.reportDownloading = false;
          let name = `BOL-${orderId}-${ReportsService.getDate()}`;
          FileSaver.saveAs(blob, name);
        });
      }
    },
  },
}
</script>