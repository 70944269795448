<template>
  <div>
    <ViewIdentity name="Pay a Bill"></ViewIdentity>
  </div>
</template>

<script>
import ViewIdentity from "@/components/ViewIdentity";

export default {
  components: {ViewIdentity}
}
</script>