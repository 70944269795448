<template>
  <div>
    <ViewIdentity name="Booked Orders"></ViewIdentity>
    <div class="container-fluid">
      <div>
        <!--    TODO: Add search critera-->
        <OrderTable></OrderTable>
      </div>
    </div>
  </div>
</template>
<script>
import OrderTable from "@/components/tables/TableOrder.vue";
import ViewIdentity from "@/components/ViewIdentity";

export default {
  props: {
    orderId: Number
  },
  components: {OrderTable, ViewIdentity}
}
</script>