<template>
    <b-card>
      <div class="text-center text-primary my-2"  v-if="isBusy">
        <b-spinner class="align-middle"></b-spinner>
        <strong>Retrieving...</strong>
      </div>
      <div v-if="!isBusy">
        <div v-for="(rate) in rates" v-bind:key="rate.quote_number">
          <b-row class="mb-2">
            <b-col sm="2"></b-col>
            <b-col sm="2"> <b>{{rate.carrier_name}}</b></b-col>
            <b-col sm="1"> Transit Days: {{rate.transit_days}}</b-col>
            <b-col sm="1"> ${{parseFloat(rate.total,2).toFixed(2)}}</b-col>
          </b-row>
          <b-row class="mb-2" v-for="(item) in rate.line_items" v-bind:key="item.description">
            <b-col sm="2"></b-col>
            <b-col sm="2">
              <span>{{item.description}} </span>
            </b-col>
            <b-col sm="3">
                <b-form-input
                  :value="parseFloat(item.amount,2).toFixed(2)"
                  readonly
                ></b-form-input>
            </b-col>
          </b-row>
          <br/>
        </div>
      </div>
      <div class="text-secondary my-2"  v-if="rates.length === 0">
        No carrier details for this order.

      </div>
    </b-card>
</template>
<script>
import QuoteService from "@/common/quotes.service";
export default {
  mounted() {
    this.id = this.$route.query?.orderId;
    if (this.id) {
      this.isBusy = true;
      QuoteService.getCarrierRatesAccepted(this.id)
      .then(rates => {
        //Hardcoding the firstone but we should get the selected by the user, once the functionality is up and running
        this.rates = [];
        if(rates && rates.length > 0){
          this.rates.push(rates[0]);          
        }
      })
      .catch(() => {
        this.notifyError("There was an error retrieving rates")
      })
      .finally(() => {
        this.isBusy = false;
      });
    }
    else{
      this.notifyError("There was an error retrieving rates, order id is invalid");
    }
  },
  data() {
    return {
      rates: {},
      isBusy: false,
      id: ""
    }
  }
}
</script>
<style>

</style>