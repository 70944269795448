import Vue from "vue";
import App from "./App.vue";
import { LMap, LTileLayer, LMarker } from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import router from "./router";
import axios from "axios";
import notify from "./mixins/notifications";
import NumericDirective from "./directives/numericDirective";
var retries = 0;

// create an interceptor for adding auth to the requests.
axios.interceptors.request.use(
  (config) => {
    // only attempt to add token header if one is set
    let token = localStorage.getItem("token");
    if (token) {
      return {
        ...config,
        headers: {
          Authorization: token,
        },
      };
    }

    return { ...config };
  },
  (error) => Promise.reject(error)
);
axios.interceptors.response.use(function (response) {
    if(retries<0)
      retries--;
    return response;
  }, function (error) {
    if(error.response && error.response.data && error.response.data.indexOf('c# session expired') >-1){
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      this.$router.push("login");
      this.notifyError("Sesion Expired");      
    }
    else{
      if(error.message === "Network Error"){
        retries++;
        if(retries > 10 ){
          return Promise.reject(error);
        }
        else{
          console.log( 'Retrying' );
          return axios.request(error.config);
        }
      }
      else{
        return Promise.reject(error);
      }
    }
  });
Vue.config.productionTip = false;

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(NumericDirective);
new Vue({
  el: "#app",
  router,
  mixins: [notify],
  render: (h) => h(App),
  data(){
    return {
      showSideBar: true
    }
  },
  beforeMount(){
    axios.defaults.baseURL = window.__env.api.apiUrl;
  }
});
Vue.component('l-map', LMap);
Vue.component('l-tile-layer', LTileLayer);
Vue.component('l-marker', LMarker); 

function activityWatcher(){
  var maxInactivity = (60 * window.__env.api.inactivityTime);

  setInterval(function(){
      if( localStorage.getItem("lastActivity") ){
        var lastActivity = new Date(localStorage.getItem("lastActivity") );
        var now = new Date();
        const diffTime = Math.abs(lastActivity - now) / 1000 ;

        //console.log(diffTime + ' seconds since the user was last active');
        //console.log( 'last activity: ' + lastActivity);
        //console.log( 'now: ' + now );
        if(diffTime > maxInactivity){
          localStorage.removeItem("token");
          localStorage.removeItem("user");
          localStorage.removeItem("lastActivity");
          localStorage.removeItem("broker");
          window.location.href="/login";
        }
      }
  }, 1000);

  //The function that will be called whenever a user is active
  function activity(){
      localStorage.setItem("lastActivity", new Date());
  }
  //An array of DOM events that should be interpreted as user activity.
  var activityEvents = ['mousedown', 'keydown', 'scroll', 'touchstart' ];

  //add these events to the document, register the activity function as the listener parameter.
  activityEvents.forEach(function(eventName) {
      document.addEventListener(eventName, activity, true);
  });
}

activityWatcher();