<template>
  <div>
    <div class="card p-3 m-1 ">
      <div v-if="!dataLoaded">
        <b-spinner
          variant="primary"
          label="Spinning"
        ></b-spinner>
      </div>
      <div v-if="dataLoaded">
        <h6 class="mt-1 ml-1 text-muted" v-if="!showPhoto">
          <i class="fa fa-id-card-alt fa-lg pr-1"></i>
          <strong>{{ broker.name }}</strong>
        </h6>
        <h6 class="mt-1 ml-1 text-muted" v-if="showPhoto">
          <img :src=broker.photo class="photo" @mouseenter="showPhotoHover = true" @mouseleave="showPhotoHover = false">
          <strong>{{ broker.name }}</strong>
        </h6>
        <h6 class="mt-1 ml-1 text-muted"><a :href="`mailto:${broker.email}`">{{ broker.email }}</a>, {{ broker.phone }}</h6>
      </div>
    </div>
  </div>
</template>
<script>
import BrokerService from "@/common/broker.service";
export default {
  mounted() {
    let localbroker = '';
    if(localStorage.getItem("broker")!= null && localStorage.getItem("broker")!='undefined'&& localStorage.getItem("broker")!=''&& localStorage.getItem("broker")!=undefined){
      localbroker = JSON.parse(localStorage.getItem("broker"));
    }

    if (localbroker) {
      this.dataLoaded = true;
      this.broker = localbroker;
      if (this.broker?.photo){
        this.showPhoto = true;
      }
    }
    else{
      BrokerService.getDetails()
      .then(data => {      
        localStorage.setItem("broker", JSON.stringify(data));
        this.dataLoaded = true;
        this.broker = data;
        if (this.broker?.photo){
          this.showPhoto = true;
        }
      });
    }

  },
  data: function () {
    return {
      broker: {},
      photo: undefined,
      showPhoto: false,
      showPhotoHover: false,
      dataLoaded: false
    }
  }
}
</script>
<style>
.photo{
  height: 64px;
  width: 80px;
}
.photoHover{
  margin-top: 64px;
  position: absolute;
  height: 300px;
  width: 400px;
  z-index: 10000;
}
</style>