<template>
  <div>
    <div class="p-1 border">
      <!--Date Pickers-->
      <b-row>
        <b-col cols="3" class="ml-3 mt-3">
          <b-form-group
              label="Start Date"
              label-for="start-date"
              label-cols-sm="4"
              label-align-sm="left"
          >
            <b-form-datepicker
                id="start-date"
                size="sm"
                :max="end"
                v-model="start"
                :date-format-options="{
                year: 'numeric',
                month: 'short',
                day: '2-digit',
                weekday: 'short',
              }"
            ></b-form-datepicker>
          </b-form-group>
        </b-col>
        <b-col cols="3" class="mt-3">
          <b-form-group
              label="End Date"
              label-for="end-date"
              label-cols-sm="4"
              label-align-sm="left"
          >
            <b-form-datepicker
                id="end-date"
                size="sm"
                :min="start"
                v-model="end"
                :date-format-options="{
                year: 'numeric',
                month: 'short',
                day: '2-digit',
                weekday: 'short',
              }"
            ></b-form-datepicker>
          </b-form-group>
        </b-col>

        <b-col cols="2" class="mt-3">
          <b-form-group
            label="Ref#"
            label-for="order-id"
            label-cols-sm="4"
            label-align-sm="left"
          >
            <b-form-input
                id="order-id"
                size="sm"
                v-model="filter.orderID"
                debounce="500"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="2" class="mt-3">
          <b-form-group
            label="Status"
            label-for="status"
            label-cols-sm="3"
            label-align-sm="left"
          >
            <custom-select
              v-model="statusId"
              :items="statuses"
              size="sm"
              item-key="description"
              :item-selected="(item) => { status = item.description; statusId = item.id}"
              :stateVal="null"
              :validValues="true"
              :validateExact="true"
              :typeAhead="true"
            ></custom-select>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="5" class="ml-3 mt-1">
          <b-form-group
              label="Origin City"
              label-for="origin-city"
              label-cols-sm="3"
              label-align-sm="left"
          >
            <b-form-input
                id="origin-city"
                size="sm"
                v-model="filter.shipperCity"
                debounce="500"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="5" class="mt-1">
          <b-form-group
              label="Destination City"
              label-for="destination-city"
              label-cols-sm="3"
              label-align-sm="left"
          >
            <b-form-input
                id="destination-city"
                size="sm"
                v-model="filter.consigneeCity"
                debounce="500"
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="5" class="ml-3 mt-1">
          <b-form-group
              label="Origin Zip"
              label-for="origin-zip"
              label-cols-sm="3"
              label-align-sm="left"
          >
            <b-form-input
                id="origin-zip"
                size="sm"
                v-model="filter.shipperZip"
                debounce="500"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="5" class="mt-1">
          <b-form-group
              label="Destination Zip"
              label-for="destination-zip"
              label-cols-sm="3"
              label-align-sm="left"
          >
            <b-form-input
                id="destination-zip"
                size="sm"
                v-model="filter.consigneeZip"
                debounce="500"
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="5" class="ml-3 mt-1">
          <b-form-group
            label="Origin Name"
            label-for="shipper-name"
            label-cols-sm="3"
            label-align-sm="left"
          >
            <LocationSearchBar
              id="shipper-name"
              :location-selected="setLocationShipper"
              @inputUpdate="shipperUpdate"
              :state="null"
              :requiredId="null"
              class="form-input"
              v-model="filter.shipperName"
              :canDelete="false"
            />
          </b-form-group>
        </b-col>
        <b-col cols="5" class="mt-1">
          <b-form-group
            label="Destination Name"
            label-for="consignee-name"
            label-cols-sm="3"
            label-align-sm="left"
          >
            <LocationSearchBar
              id="consignee-name"
              @inputUpdate="consigneeUpdate"
              :location-selected="setLocationConsignee"
              :state="null"
              :requiredId="null"
              class="form-input"
              v-model="filter.consigneeName"
              :canDelete="false"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="col-sm-3 mt-1 col-1">
          <button
            type="button"
            class="btn btn-primary mr-2"
            @click="getFilterOrderList"
          >
            Search
          </button>
          <button
            type="button"
            class="btn btn-primary mr-2"
            @click="clearFilters"
          >
            Clear Filters
          </button>
        </b-col>
        <b-col cols="auto" class="d-inline-flex ml-auto">
          <b-pagination
              class="ml-3"
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              aria-controls="order-table"
          />
          <div class="ml-3 d-inline-flex">
            <label for="perPageInput" class="col-form-label text-muted">Orders per page</label>
            <input
                class="form-control col-3 ml-2"
                type="number"
                v-model="perPage"
                id="perPageInput"
            />
            <label for="" class="ml-1 col-form-label text-muted">Max 50 results returned</label>
          </div>
        </b-col>
      </b-row>
      <!-- </div> -->
    </div>
    <b-alert
        :show="dismissCountDown"
        dismissible
        variant="danger"
        @dismissed="dismissCountDown = 0"
        @dismiss-count-down="countDownChanged"
        class="fixed-top w-25 mx-auto"
    >
      {{ alertError }}
    </b-alert>
    <!--Table-->
    <div class="row mx-auto no-gutters">
      <b-table
          fixed
          responsive
          striped
          hover
          bordered
          :items="items"
          :fields="fields"
          :current-page="currentPage"
          :per-page="perPage"
          :busy="isBusy"
      >
        <!--Row Details-->
        <template #cell(show_details)="row">
          <b-button
              size="sm"
              @click="showDetails(row.item.orderID)"
              v-on:click="detailType = 'regular'"
              class="mr-2"
          >
            Show Details
          </b-button>
        </template>
        <!--Row Details-->
        <template #cell(BOL)="row">
          <b-button
              size="sm"
              @click="getBOLReport(row.item.orderID)"
              class="mr-2"
          >
            Download BOL
          </b-button>
        </template>
        

        <!--Table loading spinner/label-->
        <template v-if="isBusy" #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle"></b-spinner>
            <span class="message">{{message}}</span>
          </div>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import OrderService from "@/common/orders.service";
import ReportsService from "@/common/reports.service";
import LocationSearchBar from "@/components/LocationSearchBar";
import FileSaver from "file-saver";
import CustomSelect from "@/components/CustomSelect";

export default {
  components: { LocationSearchBar, CustomSelect },
  mounted() {
    var status = this.$route.query.status;
    var start = this.$route.query.start;
    var end = this.$route.query.end;
    if(status || start || end ){
      this.statusId = status;
      this.start = start;
      this.end = end;
      this.status = status;
      this.getFilterOrderList();
    }
    var orderId = this.$route.query.orderId;
    if(orderId){
      this.filter.orderID = orderId;
      this.getFilterOrderList();
    }
    this.statuses = [
          { id: 'Pending Assignment', description:'Pending Assignment'},
          { id: 'To be Picked Up', description:'To Be Picked Up'},
          { id: 'In Transit', description:'In Transit'},
          { id: 'Delivered', description:'Delivered'}
        ];
  },
  computed: {
    rows() {
      return this.items ? this.items.length : 0;
    },
  },

  methods: {
    clearFilters(){
      this.filter.shipperName ='';
      this.filter.consigneeName ='';
      this.filter = [];
      this.statusId = '';
      this.start ='';
      this.end = '';
    },
    setLocationShipper(location){
        this.filter.shipperName = location.name;
    },
    setLocationConsignee(location){
        this.filter.consigneeName = location.name;
    },
    showDetails(orderId){
      this.$router.push({ name: 'order', query: { orderId: orderId } });
    },
    shipperUpdate(data){
      this.filter.shipperName = data;
    },
    consigneeUpdate(data){
      this.filter.consigneeName = data;
    },
    getBOLReport(orderId) {
      if (!this.reportDownloading) {
        this.reportDownloading = true;
        ReportsService.getBOLReport(orderId).then((blob) => {
          this.reportDownloading = false;
          let name = `BOL-${orderId}-${ReportsService.getDate()}`;
          FileSaver.saveAs(blob, name);
        });
      }
    },

    /**
     * and any filter changes.
     */

    getFilterOrderList() {
      this.isBusy = true;
      this.getMessage();
      OrderService.getFilterOrders(
          this.filter,
          this.statusId,
          this.start,
          this.end,
      )
          .then((orders) => {
            if(orders){
              this.isBusy = false;
              this.items = orders;
            }
          })
          .catch((error) => {
              this.isBusy = false;
            this.dismissCountDown = this.dismissSecs;
            this.alertError = error;
          });
    },
    getMessage(){
      this.message = 'Retrieving for: '
      if(this.start){
        this.message +='\n Start Date: ' + this.start;
      }
      if(this.end){
        this.message +='\n End Date: ' + this.end;
      }
      if(this.statusId){
        this.message +='\n Status: ' + this.statusId;
      }
      for(const key in this.filter) {
        let obj = this.filter[key];
        if (obj) {
          this.message +='\n' + key + ': ' + obj;
        }
      }

    },

    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
  data() {
    return {
      filter: {
        orderID: '',
        shipperCity: '',
        consigneeCity: '',
        shipperZip: '',
        consigneeZip: '',
        consigneeName: '',
        shipperName: '',
      },
      message: "",
      sort: null,
      detailType: "",
      start: null,
      end: null,
      status: null,
      statusId: null,
      isBusy: false,
      currentPage: 1,
      perPage: 10,
      totalRows: 1,
      detailsShowing: "Hide",
      toggleDetails: null,
      contact_name: "",
      filterOn: [],
      statuses : [],
      dismissSecs: 8,
      dismissCountDown: 0,
      alertError: null,
      // tableType: 'Review',
      fields: [
        {
          key: "orderID",
          label: "Order Number",

          sortable: true,
        },
        {
          key: "shipperName",
          label: "Origin",

          sortable: true,
        },
        {
          key: "shipperCity",
          label: "Origin City",

          sortable: true,
        },
        {
          key: "shipperState",
          label: "Origin State",
          sortable: true,
        },
        {
          key: "consigneeName",
          label: "Destination",

          sortable: true,
        },
        {
          key: "consigneeCity",
          label: "Dest City",

          sortable: true,
        },
        {
          key: "consigneeState",
          label: "Dest State",

          sortable: true,
        },
        {
          key: "statusDescr",
          label: "Order Status",

          sortable: true,
        },
        {
          key: "remark",
          label: "Last Track",
          sortable: true,
        },
        "show_details",
        "BOL"
      ],
      items: [],
      reportDownloading: false,
    };
  },
};
</script>
<style scoped>
.message{
  white-space: pre-wrap
}
</style>
