<template>
  <div class="card report" type="button">
    <h5 class="text-muted">{{ name }}</h5>
    <div
      class="btn-toolbar"
      role="toolbar"
      aria-label="Toolbar with button groups"
    >
      <div class="btn-group mr-2" role="group" aria-label="First group">
        <button type="button" class="btn btn-primary">
          <i class="fa fa-calendar mr-1"></i>Schedule
        </button>
        <button type="button" class="btn btn-success">
          <i class="fa fa-external-link-alt mr-1"></i>Create Shortcut
        </button>
      </div>
      <div v-if="name === 'Aging Report'" class="btn-group mr-2 mt-2">
        <button
          type="button"
          class="btn btn-success"
          @click="reportDownload('aging-report')"
        >
          <i class="fa fa-download mr-1"></i>Download
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import ReportsService from "@/common/reports.service";
import FileSaver from "file-saver";

export default {
  props: {
    name: String,
  },

  methods: {
    reportDownload(reportName) {
      if (!this.reportDownloading) {
        this.reportDownloading = true;
        ReportsService.getAgingReport().then((blob) => {
          this.reportDownloading = false;
          let name = `${reportName}-${ReportsService.getDate()}`;
          FileSaver.saveAs(blob, name);
        });
      }
    },
  },
};
</script>
<style>
.report {
  margin: 1rem;
  padding: 1rem;
}
</style>
