<template>
  <div>
    <div class="row">
      <div class="col-md-11">
        <b-form-input
          class="searchbox"
          v-model="queryText"
          debounce="500"
          :placeholder="placeHolder"
          @blur="hideDropDown"
          @focus="showList = true"
          autocomplete="nope"
        >
        </b-form-input>
      </div>
      <div class="mt-2 spinnerInput">
        <b-spinner small v-if="isBusy"></b-spinner>
      </div>
    </div>
    <ul
      class="list-group position-absolute"
      v-if="showList && searchResults.length>0"
    >
      <li
        class="list-group-item list-group-item-action d-flex justify-content-between align-items-center">
        <span class="col-sm-3">
          Order Id   
        </span>
        <span class="col-sm-2">
          PO # 
        </span>
        <span class="col-sm-2">
          Shipper Reference
        </span>
        <span class="col-sm-2">
          Consignee Reference
        </span>
        <span class="col-sm-2">
          PRO #
        </span>
      </li>
      <li
        class="list-group-item list-group-item-action d-flex justify-content-between align-items-center"
        v-for="(item, index) in searchResults"
        v-bind:key="index"
        @click="onEntityClick(item)"
      >
        <span class="col-sm-3">
          {{ item.orderId }} 
        </span>
        <span class="col-sm-2">
          {{ item.reference }}
        </span>
        <span class="col-sm-2">
          {{ item.shipperReference }}
        </span>  
        <span class="col-sm-2">
          {{ item.consigneeReference }}
        </span>  
        <span class="col-sm-2">
          {{ item.pro }}
        </span>  
      </li>
    </ul>
  </div>
</template>

<script>
import SearchService from "@/common/search.service";

export default {
  props: {
    isShipment: Boolean,
  },
  mounted() {
    if(this.isShipment){
      this.placeHolder = 'Track a Shipment: Order #, Pro# Ref#';
    }
  },
  name: "SearchBar",
  data() {
    return {
      queryText: "",
      searchResults: [],
      showList: false,
      isBusy: false,
      placeHolder: "Quick Search: Order #, Pro# Ref#"
    };
  },
  watch: {
    queryText(newVal) {
      this.search(newVal);
    },
  },
  methods: {
    hideDropDown: function(){
      setTimeout(() => {
         this.showList = false; 
      }, 1000)
    },
    search: function(term) {
      if (term) {
        this.isBusy = true;
        SearchService.search(term)
          .then((res) => {
            if(res){
              this.isBusy= false;
            }
            this.showList = true;
            this.searchResults = res;
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.closeSearchList();
        this.searchResults = [];
      }
    },

    onEntityClick(entityData) {
      this.closeSearchList();
      this.OrderEntityClickHandler(entityData);
    },

    OrderEntityClickHandler(entityData) {
      const status = entityData.status;
      
      switch (status) {
        case "Q":
          if (this.isShipment){
            this.$router.push({path: "quote", query: { orderId: entityData.orderId }});
          }
          else{
            this.$router.push({path: "quote", query: { orderId: entityData.orderId }});
          }
          break;
        case "A":
        case "P":
        case "D":
          if (this.isShipment){            
            this.$router.push({path: "order", query: { orderId: entityData.orderId, tracking: true  }});
          }
          else{
            this.$router.push({path: "order", query: { orderId: entityData.orderId, tracking: false }});
          }
          break;
        case "V":
          this.$bvModal.msgBoxOk("Order has been voided.", {
            title: "Message",
            size: "sm",
            buttonSize: "sm",
            okVariant: "success",
            headerClass: "p-2 border-bottom-0",
            footerClass: "p-2 border-top-0",
            centered: true,
          });
          break;
      }
    },

    closeSearchList() {
      this.showList = false;
    },
  },
};
</script>

<style scoped>
.list-group {
  width: 600px !important;
  max-width: 600px;
  z-index: 10;
}
.searchbox{
  min-width: 290px;
}
.spinnerInput{
  position: absolute;
  padding-left: 280px
}
</style>
