import axios from 'axios';

const ProductsService = {
    getProducts(location) {
        return axios.get(`/Product?location=${location}`)
        .then(res => {
            return res.data;
        });
    },
    getProductPackagings() {
        return axios.get("/Product/GetProductPacking")
        .then(res => {
            return res.data;
        });
    },
    getProductClases() {
        return axios.get("/Product/GetProductClasses")
        .then(res => {
            return res.data;
        });
    },
    getHazmatErgNumber() {
        return axios.get("/Product/GetHazmatErgNumber")
        .then(res => {
            return res.data;
        });
    },
    getHazmatPacking() {
        return axios.get("/Product/GetHazmatPacking")
        .then(res => {
            return res.data;
        });
    },
    getHazmatClasses() {
        return axios.get("/Product/GetHazmatClasses")
        .then(res => {
            return res.data;
        });
    },
    getHazmatPoisonZone() {
        return axios.get("/Product/GetHazmatPoisonZone")
        .then(res => {
            return res.data;
        });
    },
    getHazmatUnnaNbr() {
        return axios.get("/Product/GetHazmatUnnaNbr")
        .then(res => {
            return res.data;
        });
    },
    getHazmatContactType() {
        var result = [
            {
                field_code_desc : 'Person',
                field_code: 'CNID'
            },
            {
                field_code_desc : 'Contract',
                field_code: 'CNTC'
            }
        ];
        return new Promise((resolve) => {
            resolve(result);
        });
    }
}

export default ProductsService;